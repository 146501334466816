import * as Sentry from '@hanwhalife/shared-libs/sentry';
import { AxiosError, InternalAxiosRequestConfig, AxiosResponse } from '@hanwhalife/shared-libs/axios';

const HTTP_STATUS = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500
};

export declare enum Severity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
  Critical = 'critical'
}

/**
 * @param error: AxiosError<T>
 * @param message?: string
 */
export class ApiError<T = unknown> extends Error implements AxiosError<T> {
  config: InternalAxiosRequestConfig;
  code?: string;
  request?: any;
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
  toJSON: () => any;
  cause?: Error;

  constructor(error: AxiosError<T>, message?: string) {
    super(message ?? error.message);

    const errorStatus = error.response?.status || 0;
    let name = 'apiError';

    switch (errorStatus) {
      case HTTP_STATUS.BAD_REQUEST: // 400
        name = 'apiBadRequestError';
        break;
      case HTTP_STATUS.UNAUTHORIZED: // 401
        name = 'apiUnauthorizedError';
        break;
      case HTTP_STATUS.FORBIDDEN: // 403
        name = 'apiForbiddenError';
        break;
      case HTTP_STATUS.NOT_FOUND: // 404
        name = 'apiNotFoundError';
        break;
      case HTTP_STATUS.INTERNAL_SERVER_ERROR: // 500
        name = 'apiInternalServerError';
        break;
    }

    this.name = name;
    this.stack = error.stack;

    this.config = error.config!;
    this.code = error.code;
    this.request = error.request;
    this.response = error.response;
    this.isAxiosError = error.isAxiosError;
    this.toJSON = error.toJSON;
  }
}

function extractUrlParts(baseUrl: string, url: string) {
  if (!baseUrl || !url) {
    return 'unknown';
  }

  //! nci, customer, tom 외 서버 존재 시 추가
  const regex = /\/(nci|customer|tom)/;
  const matchedURL = baseUrl.match(regex);

  if (matchedURL) {
    const serverName = matchedURL[1];
    const detailedServerName = serverName === 'nci' ? url.split('/')[1] : 'unknown';

    return { serverName, detailedServerName };
  } else {
    return 'unknown';
  }
}

export function logApiErrorToSentry(response: AxiosResponse) {
  const apiErrorInstance = new ApiError(
    response as unknown as AxiosError,
    `Request failed:: [${response.config.method?.toUpperCase()}] url: ${response.config.url} ${
      response.data?.errorCode && `| errorCode: ${response.data?.errorCode} | errorMsg: ${response.data?.errorMsg}`
    }`
  );

  Sentry.setContext('API Response Detail', {
    config: {
      baseURL: response.config.baseURL,
      data: response.config.data,
      headers: response.config.headers,
      method: response.config.method,
      url: response.config.url
    },
    data: response.data
  });

  const apiServerName = extractUrlParts(response.config.baseURL!, response.config.url!);

  Sentry.withScope((scope) => {
    scope.setTags({
      type: 'api',
      channel: apiServerName === 'unknown' ? 'unknown' : apiServerName.serverName
    });
    scope.setFingerprint([
      response.config.method!,
      String(response.status),
      response.config.url!,
      response.data.errorCode ?? ''
    ]);

    Sentry.captureException(apiErrorInstance);
  });
}
