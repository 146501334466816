import styled from '@emotion/styled';

export const StyledFooter = styled.footer`
  padding: 32px 0;
  border-top: 1px solid ${({ theme }) => theme.colorBorder_3};

  .company {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    margin-bottom: 8px;
  }

  .company-item {
    margin-right: 8px;
    color: ${({ theme }) => theme.colorText_body_2};
    ${({ theme }) => theme.typography.caption}
    white-space: wrap;

    &::before {
      margin-right: 8px;
      color: ${({ theme }) => theme.colorText_neutral_2};
      content: '|';
    }

    &:first-of-type {
      &::before {
        display: none;
      }
    }
  }

  .address-wrap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colorText_body_2};
    ${({ theme }) => theme.typography.caption}
  }

  .address {
    margin: 0 8px 0 0;
  }

  .address-tel {
    display: block;
    margin-right: 8px;

    &::before {
      margin-right: 8px;
      color: ${({ theme }) => theme.colorText_neutral_2};
      content: '|';
    }
  }

  .copyright {
    margin-bottom: 1.5rem;
    color: ${({ theme }) => theme.colorText_body_2};
    ${({ theme }) => theme.typography.caption}
  }

  .tel {
    display: none;
  }

  .mark-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 24px;
  }

  .mark {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colorText_body_2};
    ${({ theme }) => theme.typography.caption}

    .mark-image {
      display: inline-flex;
      padding: 0;
      background: transparent;
      border: 0;

      /* 마크 이미지에 툴팁 노출 기능이 있는 요소 모바일에서는 동작하지 않도록 처리 */
      &:is(button) {
        pointer-events: none;
      }
    }

    .mark-text {
      margin-left: 12px;
    }
  }

  &.is-mobile-hidden {
    display: none;
  }

  ${({ theme }) => theme.media.laptop} {
    padding: 64px 0;

    &.is-mobile-hidden {
      display: block;
    }

    .company-container {
      display: flex;
      justify-content: space-between;
    }

    .company {
      row-gap: 12px;
      margin-bottom: 12px;
    }

    .address-wrap {
      row-gap: 12px;
      margin-bottom: 12px;
    }

    .copyright {
      margin-bottom: 2rem;
    }

    .tel {
      display: block;
      color: ${({ theme }) => theme.colorText_body_1};
      ${({ theme }) => theme.typography.caption}
      text-align: right;

      .tel-number {
        ${({ theme }) => theme.typography.title2}
      }
    }

    .ff-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .mark-wrap {
      flex-direction: row;
      gap: 1rem;
      margin-bottom: 0;
    }

    .mark {
      margin-bottom: 0;

      .mark-image {
        &:is(button) {
          pointer-events: unset;
        }
      }
    }
  }
`;

export const StyledFooterContainer = styled.div`
  max-width: 1176px;
  padding: 0 20px;
  margin: 0 auto;

  ${({ theme }) => theme.media.laptop} {
    padding: 0 24px;
  }
`;

export const StyledFooterNav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 20px;
  margin-bottom: 32px;

  a {
    color: ${({ theme }) => theme.colorText_body};
    ${({ theme }) => theme.typography.caption}
  }

  ${({ theme }) => theme.media.laptop} {
    gap: 12px 20px;
  }
`;

export const StyledFooterDropdown = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;

  .dropdown-button {
    position: relative;
    z-index: 2;
  }

  .dropdown-content {
    position: absolute;
    right: 0;
    bottom: 52px;
    z-index: 2;
    display: none;
    width: 200px;
    padding: 16px 0;
    background: ${({ theme }) => theme.colorBackground_0};
    border-radius: ${({ theme }) => theme.radius.borderRadius_large};
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 12%);
  }

  &.opened {
    /* stylelint-disable-next-line block-no-empty */
    .dropdown-button {
    }

    .dropdown-content {
      display: block;
    }
  }
`;

export const StyledFooterDropdownOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;
  height: 100%;

  &.opened {
    display: block;
  }
`;

export const StyledFooterDropdownContent = styled.div``;

export const StyledFooterDropdownItem = styled.a`
  display: block;
  width: 100%;
  padding: 10px 28px;
  color: ${({ theme }) => theme.colorText_body};
  ${({ theme }) => theme.typography.body2}
  text-align: left;
  background-color: transparent;
  border: 0;

  &:hover {
    color: ${({ theme }) => theme.colorText_primary};
  }
`;
