import { createContext } from 'react';
import { FallbackProps } from '@hanwhalife/shared-libs/react-error-boundary';
import { ModifyType, IFetchErrorModel } from '@hanwhalife/ts-types';

export const ErrorBoundaryContext = createContext<ModifyType<FallbackProps, { error: IFetchErrorModel }>>({
  error: {
    elapsedTime: '',
    errorCode: '',
    returnMsg: '',
    errorMsg: '',
    returnCode: '',
    returnData: {}
  },
  resetErrorBoundary: () => {}
});
