import { create } from '@hanwhalife/shared-libs/zustand';

interface IStepInfo {
  /** stepper */
  stepper: Record<string, string>[];
  isClickCTA?: boolean;
  prevStep: string;
  curStep: string;
  nextStep: string;
  totalStep: string[];
  indexObj: { [key: string]: number };
}
/** 초기 값 */
const initialState: IStepInfo = {
  stepper: [],
  isClickCTA: false,
  prevStep: '',
  curStep: '',
  nextStep: '',
  totalStep: [],
  indexObj: {}
};

interface IStep {
  stepInfo: IStepInfo;
  setStepInfo: (stepInfo: Partial<IStepInfo>) => void;
  initStepInfo: () => void;
}

/**
 * step 관리 스토어
 */
export const useStepStore = create<IStep>((set) => ({
  stepInfo: initialState,
  setStepInfo: (stepInfo) => {
    set((state) => ({
      stepInfo: { ...state.stepInfo, ...stepInfo }
    }));
  },
  initStepInfo() {
    set(() => ({ stepInfo: initialState }));
  }
}));
