import { useEffect } from 'react';
import { bridge } from '@hanwhalife/bridge';
import { BLOCK_LIST } from '@reactjs/router/routing-info';

/**
 * 뒤로가기 막기 목록 저장(for webview)
 * 저장된 URL은 네이티브에서 물리백키를 누르면 해당 리스트를 제외시킵니다.
 */
export const useSetHistoryBlockData = () => {
  useEffect(() => {
    const handleHistoryBlock = async () => {
      await bridge.saveData({
        key: 'historyBlockList',
        value: BLOCK_LIST
      });
    };
    handleHistoryBlock();
  }, []);
};
