import React, { useEffect } from 'react';
import { Modal } from '@hanwhalife/shared-libs/design-system';
import { useGlobalUIContext } from '../useGlobalUIContext';
import { IModalEntity } from '../global-ui.types';

/**
 *
 * Global Modal (Dialog)
 *  : Dialog는 여기서 렌더링
 *
 */
export const GlobalModals = () => {
  const { modal: dialog } = useGlobalUIContext();

  return (
    <>
      {dialog.list.map((data: IModalEntity, index) => (
        <Modal key={index} {...data} showModal></Modal>
      ))}
    </>
  );
};
