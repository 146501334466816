import React from 'react';
import { Spinner } from '@hanwhalife/shared-libs/design-system';
import { useGlobalUIContext } from '../useGlobalUIContext';

/**
 *
 * Spinner
 *  : Spinner 컴포넌트
 *
 */
export const GlobalSpinner = () => {
  const { spinner } = useGlobalUIContext();

  return <Spinner isSpinning={spinner.isActive} position="fixed-center" />;
};
