import { bridgeInstance } from '../bridge';
import {
  IMoveWebArgs,
  GaReqType,
  ICertManagerArgs,
  INativeResponseProps,
  BizInputType,
  IWebviewManagerArgs,
  IDynamicNetworkRequestArgs,
  IMovemoveLandingInfoArgs
} from '../bridge.types';
import { YesNo } from '@hanwhalife/ts-types';
import { checkAppVersion, isAndroid } from '@hanwhalife/shared-utils';

/**
 * Wallet Plugin
 */
export const WalletPlugin = {
  /**
   * 로그인 정보를 얻어온다.
   */
  getLoginInfo() {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'getLoginInfo',
      args: {}
    });
  },

  /**
   * 페이지 이동
   */
  moveWebPage(args: IMoveWebArgs) {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'moveWebPage',
      args
    });
  },

  /**
   * 회원가입 페이지 이동
   */
  joinPage() {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'joinPage',
      args: {}
    });
  },

  /**
   * 디바이스 정보 가져오기
   * @param args
   */
  getDeviceInfo() {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'getDeviceInfo',
      args: {}
    });
  },

  /**
   * ga data 전송
   * @param args
   * @returns
   */
  sendDataToGA(args: { reqType: GaReqType; gaData: string[] }) {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'sendDataToGA',
      args
    });
  },

  /**
   * 로그인 페이지로 이동
   */
  loginPage() {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'loginPage',
      args: {}
    });
  },

  /**
   * 히스토리 백
   * @param args
   * @returns
   */
  webBack(args: { reload: YesNo } = { reload: 'N' }) {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'webBack',
      args
    });
  },

  /**
   * 웹뷰 닫기
   * @param args
   */
  webClose(args: { reload: YesNo } = { reload: 'N' }) {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'webClose',
      args
    });
  },

  /**
   * 인증화면 호출
   * @param args
   * @returns
   */
  callCertified(args: { bizapp: 'com' | string; filename: string }) {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'callCertified',
      args
    });
  },

  /**
   * 인증서 관리자 기능 호출(키패드, 인증서가져오기, 인증서 검증, 인증서 리스트 가져오기, 인증서 삭제, 인증서 랜덤 넘버 생성, 인증서 sign, 인증서 PW 변경, 인증서 내보내기)
   * @param args
   * @returns
   */
  callCertManager(
    { bridgeAction, input }: ICertManagerArgs,
    successCallback?: (value: INativeResponseProps) => void,
    failCallback?: (value: INativeResponseProps) => void
  ) {
    return bridgeInstance.callToNative(
      {
        pluginId: 'wallet',
        command: 'callBizBridge',
        args: {
          bridgeId: 'certificate',
          bridgeAction,
          input
        }
      },
      true,
      undefined,
      successCallback,
      failCallback
    );
  },

  /**
   * Custom Webview
   * @param args
   * @returns
   */
  callWebviewManager(
    { bridgeAction, input }: IWebviewManagerArgs,
    successCallback?: (value: INativeResponseProps) => void,
    failCallback?: (value: INativeResponseProps) => void
  ) {
    return bridgeInstance.callToNative(
      {
        pluginId: 'wallet',
        command: 'callBizBridge',
        args: {
          bridgeId: 'customWebView',
          bridgeAction,
          input
        }
      },
      true,
      undefined,
      successCallback,
      failCallback
    );
  },

  /**
   * Custom Webview - 부모창 데이터 전송
   * @param args
   * @returns
   */
  sendDataFromWebivew(
    { bridgeAction, input }: IWebviewManagerArgs,
    successCallback?: (value: INativeResponseProps) => void,
    failCallback?: (value: INativeResponseProps) => void
  ) {
    return bridgeInstance.callToNative(
      {
        pluginId: 'wallet',
        command: 'callBizBridge',
        args: {
          bridgeId: 'sendData',
          bridgeAction,
          input
        }
      },
      true,
      undefined,
      successCallback,
      failCallback
    );
  },

  /**
   * 비즈앱 전용 플러그인 호출
   */
  callBizBridge(
    args: { bridgeId: string; bridgeAction: string; input: BizInputType },
    keepAlive?: boolean,
    callbackId?: string,
    successCallback?: (value: INativeResponseProps) => void,
    failCallback?: (value: INativeResponseProps) => void
  ) {
    return bridgeInstance.callToNative(
      { pluginId: 'wallet', command: 'callBizBridge', args },
      keepAlive,
      callbackId,
      successCallback,
      failCallback
    );
  },

  /**
   * 앱 종료
   * @returns
   */
  exitApp() {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'callBizBridge',
      args: {
        bridgeId: 'Window',
        bridgeAction: 'exitApp',
        input: []
      }
    });
  },
  /**
   * 내정보변경 페이지로 이동
   */
  myInfoPage() {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'myInfoPage',
      args: {}
    });
  },
  /**
   * App을 통한 네트워크 통신
   */

  dynamicNetwork(args: { url: string; request: IDynamicNetworkRequestArgs }) {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'dynamicNetwork',
      args
    });
  },

  fileDownload(args: { fileUrl: string; openPdfYn: 'Y' | 'N' }) {
    return bridgeInstance.callToNative({
      pluginId: 'wallet',
      command: 'downloadFile',
      args
    });
  },

  /**
   * 랜딩화면 기반 페이지 이동
   * @returns
   */
  moveLandingInfo(args: IMovemoveLandingInfoArgs) {
    return bridgeInstance
      .callToNative({
        pluginId: 'wallet',
        command: 'getDeviceInfo',
        args: {}
      })
      .then(({ resData }) => {
        const { appVer } = JSON.parse(resData as string);

        // 버전 체크
        const checkFlag = checkAppVersion(
          appVer,
          isAndroid()
            ? args.landingInfoApp.appVersion?.appVersionAnd || ''
            : args.landingInfoApp.appVersion?.appVersionIos || ''
        );

        // 현재 버전이 낮으면 마켓으로, 높으면 랜딩화면 이동
        if (checkFlag) {
          return bridgeInstance.callToNative({
            pluginId: 'wallet',
            command: 'navigateLandingInfoApp',
            args
          });
        } else {
          const isConfirm = confirm('앱 버전이 낮습니다. 마켓 이동할까요?');
          if (isConfirm) {
            return bridgeInstance.callToNative({
              pluginId: 'wallet',
              command: 'startMarket'
            });
          }
        }
      })
      .catch(() => {
        alert('디바이스 정보 가져오기를 실패했습니다.');
      });
  }
};
