import { NCI_API_HOST, HLP_API_HOST, BFC_API_HOST, TOM_API_HOST, MI_BASE_PATH, APP_NAME } from '@hanwhalife/constants';

// URL 정보
const NCI_BASE_URL = {
  'hlp-direct': '/api/nci',
  'hlp-micro': `${MI_BASE_PATH}/api/nci`,
  'hlp-int': NCI_API_HOST
};

const BO_BASE_URL = {
  'hlp-direct': '/api/customer',
  'hlp-micro': `${MI_BASE_PATH}/api/customer`,
  'hlp-int': BFC_API_HOST
};

const TOM_BASE_URL = {
  'hlp-direct': '/api/tom',
  'hlp-micro': `${MI_BASE_PATH}/api/tom`,
  'hlp-int': TOM_API_HOST
};

export const API_BASE_URL_MAP = {
  nci: NCI_BASE_URL[APP_NAME],
  int: `${HLP_API_HOST}/int`,
  bo: BO_BASE_URL[APP_NAME],
  tom: TOM_BASE_URL[APP_NAME]
};

// URL 정보
export const API_HOST_MAP = {
  nci: NCI_API_HOST,
  int: `${HLP_API_HOST}/int`,
  bo: BFC_API_HOST,
  tom: TOM_API_HOST
};

/**
 * NCI Custom Header Keys
 */
export const NCI_CUSTOM_HEADER_KEYS = {
  DEVICE_ID: 'X-Channel-DeviceId',
  LOGIN_TYPE: 'X-Channel-LoginType',
  SCREEN_ID: 'X-Channel-ScreenId',
  PLATFORM_NAME: 'X-Channel-PlatformName',
  PLATFORM_VERSION: 'X-Channel-PlatformVersion',
  LOGIN_CHANNEL: 'X-Channel-LoginChannel',
  APP_VERSION: 'X-Channel-AppVersion',
  CARRIER_NAME: 'X-Channel-CarrierName',
  NETWORK_TYPE: 'X-Channel-NetworkType',
  AUTHORIZATION: 'Authorization'
} as const;
