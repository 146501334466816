/**
 * 기본 메타테그 텍스트 정의
 */
export const metaMessage = {
  title: '한화생명 다이렉트',
  description:
    '한화생명 다이렉트 공식 홈페이지! 암, 건강, 정기, 치아, 어린이, 연금, 저축 등 다양한 보험상품과 대출상품을 온라인에서 쉽고 간편하게 만나보세요.',
  author: '한화생명, Hanwha Life Insurance, 한화생명 다이렉트',
  keyword:
    '한화생명,한화생명보험,다이렉트,보험,대출,암보험,정기보험,저축보험,연금저축보험,연금보험,실손의료비보험,생명보험,치아보험,어린이보험,종신보험,보험계약대출,신용대출,담보대출,암보험추천,정기보험추천,다이렉트보험,인터넷보험,온라인보험, 한화보험,암,정기,저축,연금저축,연금,실손,실비,시그니처,시그니처암보험,e시그니처,e시그니처암보험,e시그니처간편보험,간편보험,다이렉트암보험,다이렉트정기보험,다이렉트시그니처,다이렉트연금저축,다이렉트저축보험,다이렉트연금보험,다이렉트암,다이렉트정기,다이렉트저축,다이렉트연금저축,다이렉트연금,비갱신암보험,비갱신형암보험,인터넷암보험,인터넷정기보험,인터넷저축보험,인터넷연금저축보험,인터넷연금보험,온라인암보험,온라인정기보험,온라인저축보험,온라인연금저축보험,온라인연금보험'
};
