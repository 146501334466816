import React, { ComponentProps } from 'react';
import { AppSuspense } from './AppSuspense';
import { AppErrorBoundary } from './AppErrorBoundary';

interface IAsyncBoundary {
  /** Error Boundary props */
  errorProps: ComponentProps<typeof AppErrorBoundary>;

  /** Suspense props */
  suspenseProps: ComponentProps<typeof AppSuspense>;

  /** 비동기 컴포넌트 */
  children: React.ReactNode;
}

/**
 * Suspense시  try catch 문으로 사용 가능한 컴포넌트
 * @param props
 * @returns
 */
export const AppBoundary = ({ errorProps, suspenseProps, children }: IAsyncBoundary) => {
  return (
    <AppErrorBoundary {...errorProps}>
      <AppSuspense {...suspenseProps}>{children}</AppSuspense>
    </AppErrorBoundary>
  );
};
