import { ReactElement } from 'react';
import { create } from '@hanwhalife/shared-libs/zustand';

/**
 * Toast
 */
type ToastPosition = 'top-left' | 'top-right' | 'top-center' | 'bottom-left' | 'bottom-right' | 'bottom-center';
interface IToastProps {
  /** Toast ID */
  toastId: string;
  /** Toast 내용 */
  toastText: string;
  onHide: () => void;
  position?: ToastPosition;
  isAutoHide?: boolean;
  isShow?: boolean;
  duration?: number;
  icon?: 'caution' | 'error' | 'success';
  /** SVG Icon React Element */
  iconElement?: () => ReactElement;
  /** 텍스트 버튼 */
  textButton?: string;
  /** 텍스트 버튼 클릭 이벤트 */
  onTextButtonClick?: () => void;
  portalContainer?: HTMLElement | null;
  containerClass?: string;
}

interface IToast {
  toastData: IToastProps[];
  showToast: (toastItem: IToastProps) => void;
  hideToast: (toastId: string) => void;
}

export const useToastStore = create<IToast>((set) => ({
  toastData: [],
  showToast: (toastItem: IToastProps) => {
    set((state) => {
      const { toastId, isAutoHide = true } = toastItem;

      // 이미 노출된 Toast라면 패스
      const isDuplicated = state.toastData.some((item) => item.toastId === toastId);
      if (isDuplicated) {
        return {
          toastData: state.toastData
        };
      }

      // Auto Hide면 duration 체크
      if (isAutoHide) {
        // 새로운 Toast는 Duration 체크해서 노출 처리
        const timer = setTimeout(() => {
          set((state) => ({ toastData: state.toastData.filter(({ toastId }) => toastId !== toastItem.toastId) }));
          clearTimeout(timer);
        }, toastItem.duration ?? 4000);
      }

      return {
        toastData: [...state.toastData, toastItem]
      };
    });
  },
  hideToast: (id) => {
    set((state) => ({ toastData: state.toastData.filter(({ toastId }) => toastId !== id) }));
  }
}));
