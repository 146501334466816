import { useEffect } from 'react';
import { initializeStringUtils } from '@hanwhalife/shared-utils';
/**
 * 크로스 브라우징 관련 초기화
 */
export const useInitializeCrossBrowser = () => {
  useEffect(() => {
    initializeStringUtils();
  }, []);
};
