import { Button } from '@hanwhalife/shared-libs/design-system';
import styled from '@hanwhalife/shared-libs/emotion/styled';
import { keyframes } from '@hanwhalife/shared-libs/emotion/react';
import { maxWidthDesktop } from '@styled/_shared/theme';

export const StyledEventDetailWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => theme.media.laptop} {
    max-width: ${maxWidthDesktop};
    margin: 1rem auto 0;
  }
`;

export const StyledEventGridArea = styled.div`
  display: grid;
  height: 100%;
  align-items: end;

  .grid-inner {
    padding-bottom: 20px;
  }
`;

export const StyleEventLottie = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  img {
    width: 100%;
  }
`;

export const StyledEventDetailContainer = styled.div`
  padding: 0 1.25rem 2rem;
  ${({ theme }) => theme.media.laptop} {
    padding: 0 1.5rem 3rem;
  }

  .event-divider {
    height: 12px;
    margin: 32px -1.25rem;
    background: ${({ theme }) => theme.colorBorder_2};
    ${({ theme }) => theme.media.laptop} {
      height: 1px;
      margin: 48px 0;
    }
  }

  .event-divider2 {
    height: 1px;
    margin: 32px 0;
    background: ${({ theme }) => theme.colorBorder_2};
    ${({ theme }) => theme.media.laptop} {
      margin: 48px 0;
    }
  }

  .btn-list {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    ${({ theme }) => theme.media.laptop} {
      justify-content: flex-end;
      margin-top: 48px;
    }
  }

  .event-thumb {
    max-width: 100%;
    height: auto;
  }
`;

export const StyleEventTitle = styled.div`
  padding: 16px 0 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorder_2};

  h1 {
    ${({ theme }) => theme.typography.title3};
    color: ${({ theme }) => theme.colorText_body};
  }

  p {
    ${({ theme }) => theme.typography.body2};
    color: ${({ theme }) => theme.colorText_body_3};
  }

  ${({ theme }) => theme.media.laptop} {
    padding: 3rem 0;
    margin-bottom: 0;
    border-top: 1px solid ${({ theme }) => theme.colorBorder_2};
    border-bottom: 0;

    .mb-2 {
      margin-bottom: 12px;
    }
  }
`;

export const StyleEventEdit = styled.div`
  .title {
    ${({ theme }) => theme.typography.title4};
    margin: 32px 0 12px;
    color: ${({ theme }) => theme.colorText_body};
  }

  .subtitle {
    ${({ theme }) => theme.typography.subtitle2};
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colorText_body};
  }

  .body1,
  p {
    ${({ theme }) => theme.typography.body1};
    color: ${({ theme }) => theme.colorText_body};
  }

  .body2 {
    ${({ theme }) => theme.typography.body2};
    color: ${({ theme }) => theme.colorText_body_2};
  }

  a {
    color: ${({ theme }) => theme.colorText_body};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colorText_primary};
    }
  }

  .event-thumb {
    margin-top: 32px;
  }
  ${({ theme }) => theme.media.laptop} {
    .title {
      margin: 48px 0 16px;
    }

    .subtitle {
      margin-bottom: 8px;
    }

    .event-thumb {
      margin-top: 48px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const StyleEventInfo = styled.div`
  h3 {
    padding-bottom: 16px;
  }
  ${({ theme }) => theme.media.laptop} {
    h3 {
      padding-bottom: 24px;
    }
  }

  .info-list {
    white-space: pre-line;
  }
`;

export const StyledEventDetailInfoList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const StyledEventDetailInfoListItem = styled.li`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid ${({ theme }) => theme.colorBorder_2};
  border-bottom: 1px solid ${({ theme }) => theme.colorBorder_2};

  & + li {
    border-top: none;
  }

  .thumb {
    width: 72px;
    height: 72px;
    overflow: hidden;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .name {
      ${({ theme }) => theme.typography.subtitle2};
    }

    .period {
      color: ${({ theme }) => theme.colorText_body_3};
      ${({ theme }) => theme.typography.body2};
    }
  }
`;

export const StyledEventDetailContentThumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 156px;

  &.lottie-container {
    margin-top: 8rem;
  }

  .lottie-ani {
    width: 100px !important;
    height: 100px !important;
  }
`;

export const StyledContractPolicyWarningBar = styled.div`
  display: flex;
  padding-bottom: 12px;

  .item {
    position: relative;
    flex: 1 1 0%;
    color: ${(props) => props.theme.colorText_body_1};
    text-align: center;
    ${(props) => props.theme.typography.caption};

    &.active {
      &::before {
        position: absolute;
        bottom: -12px;
        left: 50%;
        width: 12px;
        height: 8px;
        margin-left: -6px;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.4 0.533333C6.2 0.266667 5.8 0.266667 5.6 0.533334L0.6 7.2C0.352786 7.52962 0.587977 8 1 8L11 8C11.412 8 11.6472 7.52962 11.4 7.2L6.4 0.533333Z' fill='%23737C85'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }

    &::after {
      display: block;
      height: 0.75rem;
      margin-top: 0.25rem;
      content: '';
    }

    &:first-of-type {
      &::after {
        background-color: #007ead;
        border-top-left-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
      }
    }

    &:nth-of-type(2) {
      &::after {
        background-color: #6cb555;
      }
    }

    &:nth-of-type(3) {
      &::after {
        background-color: #edc849;
      }
    }

    &:nth-of-type(4) {
      &::after {
        background-color: #e68a43;
      }
    }

    &:last-of-type {
      &::after {
        background-color: #d64234;
        border-top-right-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
      }
    }
  }
`;

export const StyledContractPolicyWarningBadge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.375rem;
  padding: 0 0.5rem;
  color: #fff;
  font-size: 0.75rem;
  border-radius: 3rem;

  &.grade-1 {
    background-color: #007ead;
  }

  &.grade-2 {
    background-color: #6cb555;
  }

  &.grade-3 {
    background-color: #edc849;
  }

  &.grade-4 {
    background-color: #e68a43;
  }

  &.grade-5 {
    background-color: #d64234;
  }
`;

const fadeOutIn = keyframes`
  0% { opacity: 0; transform:translateY(40px)}
  100% { opacity: 1; transform:translateY(0) }
`;

export const StyledEventButton = styled(Button)`
  animation: ${fadeOutIn} 0.3s ease-in-out;
`;

// export const StyledBannerError = styled.div`
//   width: 100%;
//   margin: 0 auto;
//   min-height: 100%;
//   flex: auto;
//   display: flex;
//   flex-direction: column;

//   ${({ theme }) => theme.media.laptop} {
//     width: 560px;
//     padding: 9.625rem 0 0;
//   }
// `;

export const StyledQrItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .store-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 56px;
    padding: 0 1rem;
    background-color: ${({ theme }) => theme.colorSurface_neutral};
    border: 1px solid ${({ theme }) => theme.colorBorder_3};
    border-radius: ${({ theme }) => theme.radius.borderRadius_medium};

    &:hover {
      @media (hover: hover) and (pointer: fine) {
        background-color: ${({ theme }) => theme.colorSurface_neutral_1};
        transition: background-color 0.2s ease-out;
      }
    }
  }

  ${({ theme }) => theme.media.laptop} {
    min-height: 780px;

    .store-link {
      width: 162px;
    }
  }
`;
