import { atom, useRecoilState, useResetRecoilState } from '@hanwhalife/shared-libs/recoil';
import { ModalType } from './portalStore.types';

// 스피너 관련 State
export const spinnerState = atom<boolean>({
  key: 'spinnerState',
  default: false
});

// 전역 모달 State set
export const defaultModalState: ModalType = {
  allowCloseModalOnBack: true,
  modalData: {
    type: 'dialog',
    showModal: true,
    title: '',
    titleDesc: '',
    headerArea: '',
    bodyContents: '',
    bodyContentsFullwidth: false,
    bodyContentsClassName: '',
    footerArea: '',
    subBtnText: '',
    subBtnDisabled: false,
    mainBtnText: '',
    mainBtnDisabled: false,
    backdropEnabled: true,
    closeIconEnabled: false,
    portalContainer: null,
    pcMinHeight: '',
    onBackdropClick: () => {},
    onCloseClick: () => {},
    onSubBtnClick: () => {},
    onMainBtnClick: () => {}
  }
};

export const ModalState = atom<ModalType[]>({
  key: 'ModalState',
  default: []
});

export const usePortalStore = () => {
  const [spinnerStore, setSpinnerStore] = useRecoilState(spinnerState);
  const [modalStore, setModalStore] = useRecoilState(ModalState);
  const closeEntireModal = useResetRecoilState(ModalState);

  // 모달 열기
  const openModal = (mData: ModalType) => {
    const { allowCloseModalOnBack = true } = mData;

    setModalStore((prev) => [...prev, { allowCloseModalOnBack, modalData: mData.modalData }]);
  };

  // 현재 모달 닫기
  const closeCurrentModal = () => {
    setModalStore((modal) => modal.filter((obj, index) => index !== modal.length - 1));
  };
  /* 
  // 모달 노출시 기본 뒤로가기 동작 세팅
  const defaultBackWardOnModal = () => {
    if (modalStore.length !== 0) {
      // allowCloseModalOnBack : 모달이 떠있을때 뒤로가기시 모달만 닫기 허용여부 (default : true)
      const { allowCloseModalOnBack = true } = modalStore[modalStore.length - 1];
      if (allowCloseModalOnBack) {
        closeCurrentModal();
      }
    }
  }; */

  return {
    spinnerStore,
    setSpinnerStore,
    modalStore,
    openModal,
    closeCurrentModal,
    closeEntireModal
  };
};
