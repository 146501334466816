import React, { ReactNode } from 'react';
import { GlobalUIContext } from './GlobalUI.context';
import { GlobalSpinner } from './spinner/GlobalSpinner.client';
import { useGlobalSpinner } from './spinner/useGlobalSpinner';
import { GlobalModals } from './modal/GlobalModals';
import { useGlobalModal } from './modal/useGlobalModal';

/**
 *
 * Global UI Provider
 * : Global UI Context를 주입
 *
 */
export function GlobalUIProvider({ children }: { children: ReactNode }) {
  // Spinner Context
  const { isActive, show, hide } = useGlobalSpinner();
  // Modal Context
  const { modalInfo, openModal, alert, confirm, closeModal, closeAllModal } = useGlobalModal();

  // Provide Context 모음
  const provideState = {
    spinner: {
      isActive,
      show,
      hide
    },
    modal: {
      list: modalInfo,
      openModal,
      alert,
      confirm,
      closeModal,
      closeAllModal
    }
  };

  return (
    <>
      <GlobalUIContext.Provider value={provideState}>
        {children}

        <GlobalSpinner />
        <GlobalModals />
      </GlobalUIContext.Provider>
    </>
  );
}
