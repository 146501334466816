import IconArs01 from './IcoArs01.svg';
import IconArs02 from './IcoArs02.svg';
import IconArs03 from './IcoArs03.svg';
import IconHeaderClose from './IcoHeaderClose.svg';
import IconHeaderBack from './IcoHeaderBack.svg';
import IconFinish from './IcoFinish.svg';
import IconReport from './IcoReport.svg';
import IconBenefit from './IcoBenefit.svg';
import IconBenefitDone from './IcoBenefitDone.svg';
import IconCalendar from './IcoCalendar40x40.svg';
import IconCall from './IcoCall.svg';
import IconContact from './IcoContact.svg';
import IconNopay from './IcoNopay.svg';
import IconPaid from './IcoPaid.svg';
import IconAgree from './IcoAgree.svg';
import IconClose24x24 from './IcoClose24x24.svg';
import IconClose from './ico-close.svg';
import IconArrBack from './arr-back.svg';
import IconUnknown from './ico-unknown.svg';
import IconArrowRight from './IconArrowRight.svg';
import IconProblem from './IconProblem.svg';
import IcoBundle from './IcoBundle.svg';
import IcoCalendar from './IcoCalendar.svg';
import IcoChat from './IcoChat.svg';
import IcoQuestion from './IcoQuestion.svg';
import IcoCounselor from './IcoCounselor.svg';
import IcoDocumentChat from './IcoDocumentChat.svg';
import IcoDocumentShield from './IcoDocumentShield.svg';
import IcoLocation2 from './IcoLocation2.svg';
import IcoMail from './IcoMail.svg';
import IcoPhone from './IcoPhone.svg';
import IcoPhoneShield from './IcoPhoneShield.svg';
import IconExclamation from './IconExclamation.svg';
import IconCamera from './IconCamera.svg';
import IconColorDocumentFolder from './IconColorDocumentFolder.svg';

export {
  IconArs01,
  IconArs02,
  IconArs03,
  IconHeaderClose,
  IconHeaderBack,
  IconFinish,
  IconReport,
  IconBenefit,
  IconBenefitDone,
  IconCalendar,
  IconCall,
  IconContact,
  IconNopay,
  IconPaid,
  IconAgree,
  IconClose24x24,
  IconClose,
  IconArrBack,
  IconUnknown,
  IconArrowRight,
  IconProblem,
  IcoBundle,
  IcoCalendar,
  IcoChat,
  IcoQuestion,
  IcoCounselor,
  IcoDocumentChat,
  IcoDocumentShield,
  IcoLocation2,
  IcoMail,
  IcoPhone,
  IcoPhoneShield,
  IconExclamation,
  IconCamera,
  IconColorDocumentFolder
};
