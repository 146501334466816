import { useState, useEffect } from 'react';

/**
 *
 * @param param0
 */
export const useScroll = ({ delay = 300, handleScroll }: { delay?: number; handleScroll: () => void }) => {
  const [throttle, setThrottle] = useState(false);

  const throttledHandleScroll = () => {
    if (throttle) {
      return;
    }
    if (!throttle) {
      setThrottle(true);
      setTimeout(async () => {
        handleScroll();
        setThrottle(false);
      }, delay);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll);

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, []);
};
