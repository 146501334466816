// 상세 가이드: https://xn--xy1bk56a.run/axios/guide/api.html#http-%EB%A9%94%EC%84%9C%EB%93%9C-%EB%B3%84%EC%B9%AD)
import axios, { CreateAxiosDefaults } from '@hanwhalife/shared-libs/axios';
import { NCI_API_HOST } from '@hanwhalife/constants';
import { IHttpClientOptions } from '../config/config.types';
import { RequestHandler, ResponseHandler, ErrorHandler } from './HttpHandler';
import { API_HOST_MAP } from '../config/config';
import { HttpClient } from './HttpClient';

const { handleDefaultRequest } = RequestHandler;
const { handleDefaultResponse, handleNciResponse, handleBoResponse } = ResponseHandler;
const { handleDefaultError } = ErrorHandler;

/**
 * 기본 axios 객체 생성
 * @param axiosConfig
 * @returns
 */
export const createAxiosInstance = (axiosConfig: CreateAxiosDefaults) => {
  const axiosInstance = axios.create({
    baseURL: axiosConfig.baseURL ?? NCI_API_HOST,
    ...axiosConfig
  });

  return axiosInstance;
};

/**
 * Server to Server(for server components)
 * @param param0
 * @returns
 */
export const createHttpServerToServer = ({
  target = 'nci',
  axiosInstanceConfig,
  onRequest,
  onResponse,
  onError
}: IHttpClientOptions = {}) => {
  // axios
  const axiosInstance = createAxiosInstance({
    baseURL: API_HOST_MAP[target],
    ...axiosInstanceConfig
  });

  axiosInstance.interceptors.request.use(onRequest ?? handleDefaultRequest, onError ?? handleDefaultError);
  axiosInstance.interceptors.response.use(
    onResponse ?? (target === 'nci' ? handleNciResponse : target === 'bo' ? handleBoResponse : handleDefaultResponse),
    onError ?? handleDefaultError
  );

  // client 생성
  const httpClient = new HttpClient(axiosInstance);

  return httpClient;
};
