/*****************************
 * 정규식 관련 유틸
 ******************************/

/** 천지인 대응 입력 정규식 */
export const cheonjiinNameRegex = /^[a-zA-Zㄱ-ㅎ가-힣|\s|\u318D\u11A2\u119E\u2025\u318D]*$/;

/** 이름 정규식 */
export const nameRegex = /(^[가-힣]+(?!\s[a-zA-Z]+)*$)|(^[a-zA-Z]+(\s[a-zA-Z]+)*$)/;

/** 영문 이름 정규식 */
export const engNameRegex = /^[a-zA-Z]+(\s[a-zA-Z]+)*$/;

/** 주민등록번호 앞자리 정규식 */
export const birthDateRegex =
  /^\d{2}([0][13-9]|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])$|^\d{2}([0][2])([0][1-9]|[1-2]\d)$/;

export const birthDate8Regex = /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;

/** 휴대전화번호 정규식 */
export const hpNumberRegex = /^(010\d{4}\d{4})|(01[16789]\d{3,4}\d{4})$/;

/** 날짜 YYYYMMDD 정규식 */
export const yyyymmddRegex = /((19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))/;

/** 한글 포함 여부 정규식 */
export const koreanRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

/** 영문 여부 정규식 */
export const englishRegex = /^[A-Za-z][A-Za-z ]*[A-Za-z]$/;

/** https, http 포함체크 */
export const httpRegex = /https?:\/\//i;

/** 자연수 체크 */
export const naturalNumber = /^[1-9]\d*$/;

/** 이메일 정규식 */
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;

/** 이모지 정규식 */
export const emojiRegex =
  /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{2300}-\u{23FF}]|[\u{2B50}]|[\u{2B55}]|[\u{1F1E6}-\u{1F1FF}]|[\u{2934}-\u{2935}]|[\u{2B05}-\u{2B07}]|[\u{3297}]|[\u{3299}]|[\u{1F004}]|[\u{1F0CF}]|[\u{303D}]|[\u{3030}]|[\u{2B06}-\u{2B07}]|[\u{24C2}]|[\u{1F170}-\u{1F171}]|[\u{1F17E}-\u{1F17F}]|[\u{1F18E}]|[\u{1F191}-\u{1F19A}]|[\u{1F201}-\u{1F202}]|[\u{1F21A}]|[\u{1F22F}]|[\u{1F232}-\u{1F236}]|[\u{1F238}-\u{1F23A}]|[\u{1F250}-\u{1F251}]|[\u{25AA}-\u{25AB}]|[\u{25B6}]|[\u{25C0}]|[\u{25FB}-\u{25FE}]|[\u{1F500}-\u{1F53D}]|[\u{1F54B}-\u{1F567}]|[\u{1F5E1}]|[\u{1F6E0}-\u{1F6E5}]|[\u{1F6E9}-\u{1F6EC}]|[\u{1F6F0}-\u{1F6F3}]|[\u{1F910}-\u{1F93E}]|[\u{1F940}-\u{1F970}]|[\u{1F973}-\u{1F976}]|[\u{1F97A}-\u{1F9CB}]|[\u{1F9CD}-\u{1FA9F}]|[\u{1FAA0}-\u{1FAF6}]|[\u{1F004}]|[\u{1F0CF}]|[\u{1F18E}]|[\u{1F191}-\u{1F19A}]|[\u{1F1E6}-\u{1F1FF}]|[\u{1F201}-\u{1F202}]|[\u{1F21A}]|[\u{1F22F}]|[\u{1F232}-\u{1F23A}]|[\u{1F250}-\u{1F251}]|[\u{203C}]|[\u{2049}]|[\u{2139}]|[\u{2194}-\u{2199}]|[\u{21A9}-\u{21AA}]|[\u{231A}-\u{231B}]|[\u{23E9}-\u{23F3}]|[\u{23F8}-\u{23FA}]|[\u{24C2}]|[\u{25AA}-\u{25AB}]|[\u{25B6}]|[\u{25C0}]|[\u{25FB}-\u{25FE}]|[\u{2600}-\u{27BF}]|[\u{2934}-\u{2935}]|[\u{2B05}-\u{2B07}]|[\u{2B1B}-\u{2B1C}]|[\u{2B50}]|[\u{2B55}]|[\u{3030}]|[\u{303D}]|[\u{3297}]|[\u{3299}]|[\u{1F90D}-\u{1F90F}]|[\u{1F98A}]|[\u{1F493}]|[\u{1F494}]/u;
