import React from 'react';
import sanitize, { IOptions } from 'sanitize-html';

export type ISanitizeHtmlProps = {
  html: string;
  options?: IOptions;
};

const defaultAllowedTags = [
  'address',
  'article',
  'aside',
  'footer',
  'header',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hgroup',
  'main',
  'nav',
  'section',
  'blockquote',
  'dd',
  'div',
  'dl',
  'dt',
  'figcaption',
  'figure',
  'hr',
  'li',
  'main',
  'ol',
  'p',
  'pre',
  'ul',
  'a',
  'abbr',
  'b',
  'bdi',
  'bdo',
  'br',
  'cite',
  'code',
  'data',
  'dfn',
  'em',
  'i',
  'kbd',
  'mark',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  'ruby',
  's',
  'samp',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'time',
  'u',
  'var',
  'wbr',
  'caption',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'img'
];

const defaultAllowedAttributes = {
  a: ['href', 'target', 'title', 'rel'],
  img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
  '*': ['style', 'class']
};

export const SanitizeHtml = ({
  html = '',
  options = {
    allowedTags: defaultAllowedTags,
    allowedAttributes: defaultAllowedAttributes
  }
}: ISanitizeHtmlProps) => {
  const sanitized = sanitize(html, {
    ...options
  });

  return <div dangerouslySetInnerHTML={{ __html: sanitized }} />;
};
