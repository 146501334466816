import React from 'react';
import { IGlobal_Modal, IModalEntity } from '@reactjs/components/_shared/context/global-ui/global-ui.types';

/**
 * 계약자 불일치 메시지
 * @param modal
 * @returns
 */
export const getNotEqualContractor = (modal: IGlobal_Modal, targetPerson: string): IModalEntity => ({
  type: 'dialog',
  title: (
    <>
      {targetPerson}(피보험자)님을 <br />
      계약자로 변경할 수 있어요
    </>
  ),
  bodyContents: (
    <div className="typography-body2 text-body2">
      계약자와 피보험자가 다른 경우에는 피보험자를 계약자로 변경 가능해요.
    </div>
  ),
  mainBtnText: '계속하기',
  subBtnText: '그만하기'
});
