import React, { createContext } from 'react';

export const PageContext = createContext({});

interface IPageContextProvider {
  /** children */
  children: React.ReactNode;

  /** Provider Value */
  value: Record<string, unknown>;
}

/**
 * 페이지에서 사용될 컨텍스트 API
 * @param param0
 * @returns
 */
export const PageContextProvider = ({ value, children }: IPageContextProvider) => {
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};
