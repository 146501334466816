import { AxiosRequestConfig, AxiosInstance } from '@hanwhalife/shared-libs/axios';

/**
 * Http 통신 어댑터
 */
export class HttpClient {
  private instance;
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  /**
   * HTTP Get
   * @param url
   * @param config
   * @returns
   */
  async get(url: string, config?: AxiosRequestConfig) {
    return (await this.instance.get(url, config)).data;
  }

  /**
   * HTTP Post
   * @param url
   * @param param
   * @param config
   * @returns
   */
  async post(url: string, param?: Record<string, unknown> | string | FormData, config?: AxiosRequestConfig) {
    return (await this.instance.post(url, param, config)).data;
  }

  /**
   * HTTP Put
   * @param url
   * @param param
   * @param config
   * @returns
   */
  async put(url: string, param?: Record<string, unknown> | string, config?: AxiosRequestConfig) {
    return (await this.instance.put(url, param, config)).data;
  }

  /**
   * HTTP Delete
   * @param url
   * @param param
   * @param config
   * @returns
   */
  async delete(url: string, config?: AxiosRequestConfig) {
    return (await this.instance.delete(url, config)).data;
  }
}
