import React, { useEffect } from 'react';
import { useAuthTokenIntrospectForNoneCookieQuery } from '@hanwhalife/http-methods/nci/auth/useAuthQuery';
import { useLoginInfo } from '@hanwhalife/shared-store';
import { useModalHandler } from '@reactjs/hooks/ui/modal/useModalHandler';
import { useHlpRouter } from '@reactjs/router';
import { COMMON_MSG } from './assets/UnAuthorizedMessage';

/**
 * 401 체크(채널통합용, Cookie 미사용)
 * @returns
 */
export const UnAuthorizedCheck = () => {
  const { showSimpleModal } = useModalHandler();
  const router = useHlpRouter();
  const {
    loginInfo: { isLogined, accessToken },
    setLoginInfo
  } = useLoginInfo();

  // introspect
  const { isError, error } = useAuthTokenIntrospectForNoneCookieQuery(
    {
      token: accessToken,
      tokenTypeHint: 'access_token'
    },
    {
      enabled: isLogined,
      retry: 1,
      refetchInterval: 1000 * 60
    }
  );

  useEffect(() => {
    if (isError && error.returnCode === '401') {
      const handleLoginExpired = async () => {
        const { title, bodyContents, mainBtnText } = COMMON_MSG;

        setLoginInfo({ accessToken: '', refreshToken: '', isLogined: false });

        showSimpleModal({
          uniqueId: 'EXPIRE_TOKEN',
          type: 'dialog',
          title,
          bodyContents,
          mainBtnText,
          onMainBtnClick: () => {
            router.loginPage();
          },
          portalContainer: document?.getElementById('_important-modal')
        });
      };

      handleLoginExpired();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error]);

  return <></>;
};
