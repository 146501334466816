import React from 'react';
import Head from 'next/head';

interface IHtmlTitle {
  /** HTML Title */
  title: string;
}
/**
 * HTML Header Component
 * @param param0
 * @returns
 */
export function HtmlTitle({ title }: IHtmlTitle) {
  return (
    <Head>
      <title>{title}</title>
    </Head>
  );
}
