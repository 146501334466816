import React from 'react';

export const error_primary = {
  errorCode: '000',
  actionMsg: '다시 시도해보세요',
  errorTitle: (
    <>
      일시적인 오류로
      <br />
      정보를 불러올 수 없어요
    </>
  ),
  errorDesc: <>정보를 확인하시려면 다시 시도해 보세요.</>,
  errorMessage: <div>오류가 발생했습니다.</div>
};

export const serverError_primary = {
  errorCode: '500',
  actionMsg: '다시 시도해보세요',
  errorMessage: (
    <div>
      서버에서 정보를 가져오는 중에
      <br /> 오류가 발생했습니다.
    </div>
  )
};
