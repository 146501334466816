import React from 'react';

/*****************************
 * JSX 관련 유틸
 ******************************/

export const convertNewLineToJSX = (str: string) => {
  return str.split('\n').map((line, index) => {
    return (
      <React.Fragment key={`${line}-${index}`}>
        {index > 0 ? <br /> : ''}
        {line}
      </React.Fragment>
    );
  });
};
