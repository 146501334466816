import React, { useState, useEffect } from 'react';
import { RecoilRoot } from '@hanwhalife/shared-libs/recoil';
import * as Sentry from '@hanwhalife/shared-libs/sentry';
import { QueryClient, QueryClientProvider } from '@hanwhalife/shared-libs/react-query';
import { ReactQueryDevtools } from '@hanwhalife/shared-libs/react-query-devtools';
import { HliThemeProvider, HliGlobalStyle } from '@hanwhalife/design-system';
import { PortalProvider } from '@/components/_shared/context';
import { AppErrorBoundary, ErrorFallback } from '@/components/_shared/boundaries';
import { GlobalUIProvider, HlpGlobalUIs } from '@hanwhalife/reactjs/components/_shared';
import { GlobalStyled } from '@hanwhalife/styled/_shared';
import { Modals } from '@/components/common/modal/Modals';
import { Initialize } from '../init/Initialize';
import { MSW_ENABLED } from '@hanwhalife/constants';
import { UnAuthorizedCheck } from '@hanwhalife/reactjs/components/login-status/post-process/UnAuthorizedCheck';

const queryClient = new QueryClient();

/**
 * Provider 집합
 * @returns
 */
export const Providers = ({ children }: { children: React.ReactNode }) => {
  // MSW worker가 실행되거나, 필요하지 않을 경우 false
  const [isMswInitializing, setIsMswInitializing] = useState(() => MSW_ENABLED === 'true');

  useEffect(() => {
    const initMocking = async () => {
      const { enableMocking } = await import('@hanwhalife/reactjs/mocks/browser');
      await enableMocking();

      setIsMswInitializing(false);
    };

    if (isMswInitializing) {
      initMocking();
    }
  }, [isMswInitializing]);

  if (isMswInitializing) {
    return null;
  }

  return (
    <HliThemeProvider>
      <HliGlobalStyle />
      <GlobalStyled />
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <AppErrorBoundary
            FallbackComponent={() => <ErrorFallback />}
            onError={(error) => {
              Sentry.captureException(error);
            }}
          >
            <PortalProvider>
              <GlobalUIProvider>
                <Initialize />
                <HlpGlobalUIs />
                <UnAuthorizedCheck />
                {children}

                {/* 사용여부 확인 필요 */}
                <Modals />
              </GlobalUIProvider>
            </PortalProvider>
          </AppErrorBoundary>
          <ReactQueryDevtools initialIsOpen={false} />
          <div id="_important-modal" style={{ zIndex: 9999 }} className="p-relative" />
        </QueryClientProvider>
      </RecoilRoot>
    </HliThemeProvider>
  );
};
