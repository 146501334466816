import { bridgeInstance } from '../bridge';
import { IKeyData, IKeyValueData, CmdCommonType, CmdWalletType } from '../bridge.types';

/**
 * Common Plugin
 */
export const CommonPlugin = {
  /**
   * 로드 데이터
   * @param args
   */
  loadData(args: IKeyData) {
    return bridgeInstance.callToNative({
      pluginId: 'common',
      command: 'loadData',
      args
    });
  },

  /**
   * Save 데이터
   * @param args
   */
  saveData(args: IKeyValueData) {
    return bridgeInstance.callToNative({
      pluginId: 'common',
      command: 'saveData',
      args
    });
  },

  /**
   * 보이는 ars
   * @returns
   */
  initCallgate() {
    return bridgeInstance.callToNative({
      pluginId: 'common',
      command: 'initCallgate',
      args: {}
    });
  },

  /**
   * 해당 커맨드가 지원해주는지 여부 체크
   * @returns
   */
  isSupport(command: CmdCommonType | CmdWalletType) {
    return bridgeInstance.callToNative({
      pluginId: 'common',
      command: 'support',
      args: {
        command
      }
    });
  },

  /**
   * SaveData를 삭제
   */
  removeData(args: IKeyData) {
    return bridgeInstance.callToNative({
      pluginId: 'common',
      command: 'removeData',
      args
    });
  }
};
