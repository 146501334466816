import { useState, useEffect, useMemo } from 'react';
import { Modal, IModalProps } from '@hanwhalife/shared-libs/design-system';
import { useHlpRouter } from '@reactjs/router';
import { customizeBackPressedFunc } from '@reactjs/hooks/init/useInitializeAppWebbFunctions';
import { UniqueModalType, useModalStore, IModalEntity } from '@hanwhalife/shared-store/zustand/global-ui';
import { useLoginInfo } from '@hanwhalife/shared-store';
import { useShallow } from '@hanwhalife/shared-libs/zustand';

interface IModalHandlerProps {
  // 생성할 모달 State
  [key: string]: boolean;
}

/**
 *
 * HLP Modal Handler
 *
 * {
 *    modal1 : false,
 *    modal2 : false
 * }
 *
 */
export const useModalHandler = (modals?: IModalHandlerProps) => {
  const router = useHlpRouter();
  const { loginInfo } = useLoginInfo();

  // Global Modal Store : Zustand
  const {
    dialogList,
    openDialog,
    closeDialog,
    closeAllDialog,
    activeUniqueModalList,
    addUniqueId,
    removeUniqueId,
    removeAllUniqueId
  } = useModalStore(
    useShallow((state) => ({
      dialogList: state.dialogList,
      openDialog: state.openDialog,
      closeDialog: state.closeDialog,
      closeAllDialog: state.closeAllDialog,
      activeUniqueModalList: state.activeUniqueModalList,
      addUniqueId: state.addUniqueId,
      removeUniqueId: state.removeUniqueId,
      removeAllUniqueId: state.removeAllUniqueId
    }))
  );

  // 컴포넌트와 공유되는 Modal State : HLP Modal
  const [modalState, setModalState] = useState(modals ?? {});
  // 활성화된 모달 순서 Array
  const [orderActivated, setOrderActivated] = useState<string[]>([]);

  // Activate Tab Trap in Modal
  const initTabTrap = (trapWrapperEl?: string) => {
    // Trap 영역 Default는 #_ds-modal
    const wrapper = document.querySelector(trapWrapperEl || '#_ds-modal');

    if (wrapper) {
      const focusableEls = wrapper.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
      );
      const firstFocusableEl = focusableEls[0] as HTMLElement;
      const lastFocusableEl = focusableEls[focusableEls.length - 1] as HTMLElement;
      const KEYCODE_TAB = 9;

      document.addEventListener('keydown', (e) => {
        const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          /* shift + tab */
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
            e.preventDefault();
          }
        } else {
          /* tab */
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
            e.preventDefault();
          }
        }
      });
    }
  };

  // Show HLP Modal
  const showHlpModal = (modal: string, uniqueModal?: UniqueModalType) => {
    // uniqueModal이 들어왔으면, 해당 값이 activeUniqueModalList에 있는지 체크
    if (uniqueModal) {
      // 있으면 모달 열기 X. return;
      if (activeUniqueModalList.includes(uniqueModal)) {
        return;
      }

      // 없으면 addUniqueId & 진행
      addUniqueId(uniqueModal);
    }

    setModalState((prev: Record<string, boolean>) => ({
      ...prev,
      [modal]: true
    }));
    setOrderActivated([...orderActivated, modal]);
  };
  // Hide HLP Modal
  const hideHlpModal = (modal: string, uniqueModal?: UniqueModalType) => {
    // uniqueModal이 들어왔으면, 해당 값이 activeUniqueModalList에 있는지 체크
    if (uniqueModal) {
      if (activeUniqueModalList.includes(uniqueModal)) {
        removeUniqueId(uniqueModal);
      }
    }

    setModalState((prev: Record<string, boolean>) => ({
      ...prev,
      [modal]: false
    }));

    setOrderActivated((cur) => cur.filter((item) => item !== modal));
  };

  // Hide All Modal
  const hideAllModal = () => {
    Object.keys(modalState).map((item) => {
      setModalState((prev: Record<string, boolean>) => ({
        ...prev,
        [item]: false
      }));
    });
    closeAllDialog();
    removeAllUniqueId();
  };

  // Show Simple Modal : Zustand
  const showSimpleModal = (dialogData: Partial<IModalEntity>) => {
    openDialog({
      type: 'dialog',
      title: '알림',
      mainBtnText: '확인',
      onMainBtnClick(e) {
        hideSimpleModal();
      },
      ...dialogData
    });
  };
  // Show Simple Async Modal : Zustand
  const showSimpleAsyncModal = (dialogData: Partial<IModalEntity>) => {
    return new Promise((resolve, reject) => {
      openDialog({
        type: 'dialog',
        title: '알림',
        mainBtnText: '확인',
        ...dialogData,
        onMainBtnClick() {
          dialogData.onMainBtnClick ? dialogData.onMainBtnClick() : hideSimpleModal();
          resolve(true);
        },
        onSubBtnClick() {
          dialogData.onSubBtnClick ? dialogData.onSubBtnClick() : hideSimpleModal();
          resolve(true);
        },
        onCloseClick() {
          dialogData.onCloseClick ? dialogData.onCloseClick() : hideSimpleModal();
          resolve(true);
        }
      });
    });
  };

  // hide Simple Modal : Zustand
  const hideSimpleModal = () => {
    closeDialog();
  };

  // type = 'dialog'가 떠있는 상태
  const isDialogRendered = () => {
    const modalWrap = document.querySelector('#_ds-modal');
    const lastModal = modalWrap?.querySelectorAll('[tabindex]')[modalWrap?.querySelectorAll('[tabindex]').length - 1];

    return !!lastModal?.querySelector('.modal-dialog');
  };

  // 뒤로가기 제어 : 한화생명 앱(웹뷰)
  const handleBackwardOnModal = (modals: string[]) => {
    if (dialogList.length > 0) {
      // Simple Modal 부터 닫기 : Zustand
      customizeBackPressedFunc(() => {
        if (!isDialogRendered()) {
          hideSimpleModal();
        }
      });
    } else {
      // 아니면 HlpModal
      if (modals.length > 0) {
        customizeBackPressedFunc(() => {
          if (!isDialogRendered()) {
            hideHlpModal(orderActivated[orderActivated.length - 1]);
          }
        });
      } else {
        customizeBackPressedFunc(() => {
          router.backScreen({
            byHardware: true
          });
        });
      }
    }
  };

  //Set Modal Renderer. Memoization. HLP Modal
  const ModalRenderer = (data: Partial<IModalProps>) => {
    return <Modal {...data} bodyContentsClassName={`modal-${data.type || 'dialog'}`} />;
  };
  const HlpModal = useMemo(() => ModalRenderer, []);

  // 뒤로가기 핸들러
  useEffect(() => {
    if (loginInfo.loginChannel !== 'DIRECT') {
      handleBackwardOnModal(orderActivated);
    }
  }, [orderActivated, dialogList, loginInfo.loginChannel]);

  // 라우팅에 따른 모달 제거
  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      hideAllModal();
    });

    return () => {
      router.events.off('routeChangeStart', () => {
        hideAllModal();
      });
    };
  }, []);

  return {
    modalState,
    activeUniqueModalList,
    HlpModal,
    showHlpModal,
    hideHlpModal,
    hideAllModal,
    initTabTrap,
    showSimpleModal,
    showSimpleAsyncModal,
    hideSimpleModal
  };
};
