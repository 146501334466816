import { create } from '@hanwhalife/shared-libs/zustand';
import { IModalProps } from '@hanwhalife/shared-libs/design-system';

// 중복 호출을 막아야 하는 모달 예약어
export type UniqueModalType = 'EXPIRE_TOKEN' | 'PHONE_NUM_CHANGE' | 'AML_MODAL';

export interface IModalEntity extends IModalProps {
  /** Modal */
  uniqueId?: UniqueModalType | null;
}

/**
 * IModalStore
 */
interface IModalStore {
  dialogList: IModalEntity[];
  openDialog: (modal: IModalEntity) => void;
  closeDialog: () => void;
  closeAllDialog: () => void;

  activeUniqueModalList: UniqueModalType[];
  addUniqueId: (name: UniqueModalType) => void;
  removeUniqueId: (name: UniqueModalType) => void;
  removeAllUniqueId: () => void;
}

export const useModalStore = create<IModalStore>((set) => ({
  dialogList: [],
  activeUniqueModalList: [],
  openDialog: (modal: Partial<IModalEntity>) => {
    console.log('Zustand@@ open');
    set((state) => {
      const { uniqueId } = modal;

      // 중복 모달 노출 방지
      if (uniqueId) {
        // 이미 열려있는 모달이면 변화 없음
        if (state.activeUniqueModalList.includes(uniqueId)) {
          return {
            dialogList: state.dialogList
          };
        }
        state.addUniqueId(uniqueId);
      }

      return {
        dialogList: [...state.dialogList, modal]
      };
    });
  },
  closeDialog: () => {
    console.log('Zustand@@ close');

    set((state) => {
      const targetModal = state.dialogList[state.dialogList.length - 1];

      // 제거될 모달에 uniqueId가 있으면
      if (targetModal.uniqueId) {
        state.removeUniqueId(targetModal.uniqueId);
      }

      return {
        dialogList: state.dialogList.slice(0, state.dialogList.length - 1)
      };
    });
  },
  closeAllDialog: () => {
    set((state) => ({
      dialogList: []
    }));
  },

  addUniqueId: (id) => {
    set((state) => ({
      activeUniqueModalList: [...state.activeUniqueModalList, id]
    }));
  },
  removeUniqueId: (id) => {
    set((state) => ({
      activeUniqueModalList: state.activeUniqueModalList.filter((name) => name !== id)
    }));
  },
  removeAllUniqueId: () => {
    set((state) => ({
      activeUniqueModalList: []
    }));
  }
}));
