import { AxiosResponse, InternalAxiosRequestConfig, AxiosError } from '@hanwhalife/shared-libs/axios';
import * as Sentry from '@hanwhalife/shared-libs/sentry';
import { logApiErrorToSentry } from '../config/sentry-config';

/**
 * 요청 핸들러
 */
export const RequestHandler = {
  handleDefaultRequest(reqConfig: InternalAxiosRequestConfig) {
    console.dir(`요청 Config:: ${reqConfig}`);

    return reqConfig;
  }
};

/**
 * 응답핸들러
 */
export const ResponseHandler = {
  handleNciResponse(response: AxiosResponse) {
    console.log(`요청 URL:: ${response.config.url}, 응답 값::`, response);

    // NCI 기본
    const resData = response.data;
    const { errorCode, errorMsg, returnCode, elapsedTime } = resData;

    //  응답값이 returnCode가 00이 아니거나 에러코드가 있으면 reject
    if (returnCode !== '00' || errorCode) {
      console.error(
        `returnCode::${returnCode}, errorCode::${errorCode}, errorMessage::${errorMsg}, elapsedTime::${elapsedTime}`
      );

      logApiErrorToSentry(response);

      return Promise.reject(resData);
    }

    return response;
  },
  handleBoResponse(response: AxiosResponse) {
    console.log(`요청 URL:: ${response.config.url}, 응답 값::`, response);

    // BO
    const bizHeader = response.data.header;

    // bizError의 경우  HTTP 200, data header에 error코드가 200이 아닐때 에러로 간주.
    if (bizHeader && bizHeader.errorCode && bizHeader.errorCode !== '200' && bizHeader.errorCode !== 200) {
      const { errorCode, errorMessage, elapsedTime } = bizHeader;
      const errorInfo = `errorCode::${errorCode}, errorMessage::${errorMessage},  elapsedTime::${elapsedTime}`;
      console.error(errorInfo);

      Sentry.captureException(response); // TODO: 실제 값 확인 후 처리

      return Promise.reject(bizHeader);
    }

    return response;
  },
  handleDefaultResponse(response: AxiosResponse) {
    console.log(`요청 URL:: ${response.config.url}, 응답 값::`, response);

    return response;
  }
};

/**
 * 에러 핸들러
 */
export const ErrorHandler = {
  handleDefaultError(error: AxiosError) {
    console.error('error:', error);

    const { code, message, request, response } = error;
    if (response) {
      // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
      console.error('요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.');

      const { data, status, statusText, headers } = response;
      console.error('HTTP data, status, headers:::', data, status, headers);

      logApiErrorToSentry(response);

      return Promise.reject({
        elapsedTime: '',
        errorCode: code,
        errorMsg: message,
        returnCode: `${status}`,
        returnData: '',
        returnMsg: `${statusText}`
      });
    } else if (request) {
      // 요청이 이루어 졌으나 응답을 받지 못했습니다.
      console.error('요청이 이루어 졌으나 응답을 받지 못했습니다.');
      // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
      // Node.js의 http.ClientRequest 인스턴스입니다.
      console.error(request);

      Sentry.captureException(request);
    } else {
      // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
      console.error('오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.');
      console.error('Error', message);

      Sentry.captureException(error);
    }

    // promise reject
    return Promise.reject({
      elapsedTime: '',
      errorCode: code,
      errorMsg: message,
      returnCode: '',
      returnData: '',
      returnMsg: ''
    });
  }
};
