/*****************************
 * Browser Object Model 관련 유틸
 ******************************/

/**
 * (for webview) UserAgent(window 객체가 존재할때만 사용 가능(Client Side)) (모바일 브라우저여도 Webview가 아니면 웹)
 * @returns 'Android' | 'iOS' | 'Web'
 */
export const getWebViewUserAgent = (): 'Android' | 'iOS' | 'Web' => {
  // 웹뷰 여부
  if (isHlpWebview()) {
    return getUserAgent();
  }

  return 'Web';
};

/**
 * (for web) UserAgent(window 객체가 존재할때만 사용 가능(Client Side))
 * @returns 'Android' | 'iOS' | 'Web'
 */
export const getUserAgent = (): 'Android' | 'iOS' | 'Web' => {
  //체크
  if (isAndroid()) {
    return 'Android';
  }

  if (isIos()) {
    return 'iOS';
  }

  return 'Web';
};

/**
 * HLP Webview 여부
 * @returns
 */
export const isHlpWebview = () => {
  const ua = window.navigator.userAgent;

  return ua.indexOf('HANWHALIFE') > -1 || ua.indexOf('HoneMobile') > -1;
};

/**
 * 안드로이드 여부
 * @returns
 */
export const isAndroid = () => /android/i.test(window.navigator.userAgent.toLowerCase());

/**
 * iOS 여부
 * @returns
 */
export const isIos = () => /iphone|ipad|ipod/i.test(window.navigator.userAgent.toLowerCase());

/**
 * 삼성브라우저 여부
 * @returns
 */
export const isSamsungBrowser = () => /samsungbrowser/i.test(window.navigator.userAgent.toLowerCase());

/**
 * 모바일 기기인지 체크
 * @returns boolean
 */
export const isMobile = () => document && 'ontouchstart' in document.documentElement;

/**
 * PC 기기인지 체크
 * @returns boolean
 */
export const isPc = () => document && !isMobile();

/**
 * 클라이언트 ID
 * for client 용
 * @returns
 */
export const getScreenId = () => {
  return `${encodeURI(location.href)}`;
};

/**
 * 브라우저 정보를 가져옵니다.
 * @returns
 */
export const getBrowserInfo = () => {
  const ua = navigator.userAgent;
  let browserName;
  let browserVersion;

  if (/firefox|fxios/i.test(ua)) {
    browserName = 'Firefox';
    browserVersion = ua.match(/(?:firefox|fxios)\/([0-9\.]+)/i)?.[1];
  } else if (/chrome|crios|crmo/i.test(ua) && !/edg/i.test(ua)) {
    browserName = 'Chrome';
    browserVersion = ua.match(/(?:chrome|crios|crmo)\/([0-9\.]+)/i)?.[1];
  } else if (/edg/i.test(ua)) {
    browserName = 'Edge';
    browserVersion = ua.match(/edg\/([0-9\.]+)/i)?.[1];
  } else if (/safari/i.test(ua) && !/chrome|crios|crmo|edg/i.test(ua)) {
    browserName = 'Safari';
    browserVersion = ua.match(/version\/([0-9\.]+)/i)?.[1];
  } else if (/opr\//i.test(ua)) {
    browserName = 'Opera';
    browserVersion = ua.match(/opr\/([0-9\.]+)/i)?.[1];
  }

  return { browserName: browserName ?? 'Unknown', browserVersion: browserVersion ?? 'Unknown' };
};

/**
 * 브라우저 이름
 * @returns
 */
export const getBrowserName = (): string => {
  return getBrowserInfo().browserName;
};

/**
 * 브라우저 버전
 * @returns
 */
export const getBrowserVersion = (): string => {
  return getBrowserInfo().browserVersion;
};

/**
 * OS 이름을 가져옵니다.
 */
export const getOsName = () => {
  // browser
  const { userAgent } = navigator;

  let osName = '';

  for (const id in clientStrings) {
    const cs = clientStrings[id];
    if (cs.r.test(userAgent)) {
      osName = cs.s;
      break;
    }
  }

  if (/Windows/.test(osName)) {
    osName = 'Windows';
  } else if (/Mac/.test(osName)) {
    osName = 'Mac';
  }

  return osName;
};

/**
 * OS Version을 가져옵니다.
 */
export const getOsVersion = () => {
  // browser
  const { userAgent } = navigator;

  let osVersion = '';
  let _osName = '';

  for (const id in clientStrings) {
    const cs = clientStrings[id];
    if (cs.r.test(userAgent)) {
      _osName = cs.s;
      break;
    }
  }

  if (/Windows/.test(_osName)) {
    osVersion = /Windows (.*)/.exec(_osName)?.[1] ?? 'unknown';
    _osName = 'Windows';
  }

  switch (_osName) {
    case 'Mac OS':
    case 'Mac OS X':
    case 'Android':
      osVersion =
        /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(userAgent)?.[1] ??
        'unknown';
      break;

    case 'iOS':
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(userAgent) as any;
      osVersion = `${osVersion[1]}.${osVersion[2]}.${osVersion?.[3] ?? 0 | 0}`;
      break;
  }

  return osVersion;
};

const clientStrings = [
  { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
  { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
  { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
  { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
  { s: 'Windows Vista', r: /Windows NT 6.0/ },
  { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
  { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
  { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
  { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
  { s: 'Windows 98', r: /(Windows 98|Win98)/ },
  { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
  { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
  { s: 'Windows CE', r: /Windows CE/ },
  { s: 'Windows 3.11', r: /Win16/ },
  { s: 'Android', r: /Android/ },
  { s: 'Open BSD', r: /OpenBSD/ },
  { s: 'Sun OS', r: /SunOS/ },
  { s: 'Chrome OS', r: /CrOS/ },
  { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
  { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
  { s: 'Mac OS X', r: /Mac OS X/ },
  { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
  { s: 'QNX', r: /QNX/ },
  { s: 'UNIX', r: /UNIX/ },
  { s: 'BeOS', r: /BeOS/ },
  { s: 'OS/2', r: /OS\/2/ },
  { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
];

/**
 * 오픈할 popup을 브라우저 중앙에 위치시킬 left, top 값을 가져옵니다.
 * @returns
 */
export const getPopupCenter = (width: number, height: number) => {
  let left = document.body.offsetWidth / 2 - width / 2;
  let top = height / 4;

  left += window.screenLeft;
  top += window.screenTop;

  return { left, top };
};
