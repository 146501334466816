import styled from '@hanwhalife/shared-libs/emotion/styled';

export const StyledAuthGuide = styled.div`
  width: 100%;
  padding: 1rem 0 2rem;
  text-align: left;

  ${({ theme }) => theme.media.laptop} {
    padding: 0 0 2rem;
  }
`;

export const StyledAuthFixedFooter = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 0.5rem;
  padding: 0 0 2rem;
  background-color: ${({ theme }) => theme.colorBackground_0};

  &::before {
    position: absolute;
    top: -1.25rem;
    left: 0;
    width: 100%;
    height: 1.25rem;
    content: '';
    background: linear-gradient(0deg, #fff.5%, rgb(255 255 255 / 0%) 100%);
  }

  ${({ theme }) => theme.media.laptop} {
    position: static;
    padding: 0 0 2rem;

    &::before {
      display: none;
    }
  }
`;

export const StyledAuthMethodListWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1.5rem 1.25rem 2rem;
  margin: 0 -1.25rem -2rem;
  background: ${({ theme }) => theme.colorBackground_1};

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  button {
    box-shadow: 0 5px 8px rgb(0 0 0 / 2%);
  }

  ${({ theme }) => theme.media.laptop} {
    padding: 0;
    margin: 0;
    background: none;

    ul {
      gap: 0;
    }

    button {
      box-shadow: none;
    }
  }
`;

export const StyledAuthMethodItem = styled.span`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  padding: 0.5rem 0.25rem;
  text-align: left;

  .text-wrap {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .title {
    color: ${({ theme }) => theme.colorText_body};
    ${({ theme }) => theme.typography.subtitle2}
  }

  .description {
    margin-top: 0.25rem;
    color: ${({ theme }) => theme.colorText_body_2};
    ${({ theme }) => theme.typography.body2}
  }

  ${({ theme }) => theme.media.laptop} {
    padding: 0;
  }
`;

/** //TODO: DS TextField 예외처리
 * 주민등록번호 뒷자리 1자리 입력
 */
export const StyledIdCardBackNumber = styled.div<{ isError?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 2.75rem;
  height: 3.5rem;
  padding: 0 1rem;
  background-color: ${({ theme }) => theme.colorSurface_neutral_1};
  border: 1px solid
    ${({ theme, isError = false }) => (isError ? theme.colorAdditional_semantic_2 : theme.colorBorder_3)};
  border-radius: ${({ theme }) => theme.radius.borderRadius_medium};

  &:has(input:focus) {
    border-color: ${({ theme }) => theme.colorSurface_secondary};
  }

  &::after {
    position: absolute;
    left: 52px;
    display: block;
    width: 92px;
    height: 56px;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='92' height='12' viewBox='0 0 92 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23272B2F'/%3E%3Ccircle cx='22' cy='6' r='6' fill='%23272B2F'/%3E%3Ccircle cx='38' cy='6' r='6' fill='%23272B2F'/%3E%3Ccircle cx='54' cy='6' r='6' fill='%23272B2F'/%3E%3Ccircle cx='70' cy='6' r='6' fill='%23272B2F'/%3E%3Ccircle cx='86' cy='6' r='6' fill='%23272B2F'/%3E%3C/svg%3E%0A");
    background-repeat: repeat-x;
    background-position: 0 50%;
  }

  &:hover:not(:has(input:disabled)) {
    box-shadow: inset 100rem 100rem ${({ theme }) => theme.colorState_dark_1};
  }

  input {
    width: 100%;
    height: 100%;
    padding: 0;
    color: ${({ theme }) => theme.colorText_body};
    ${({ theme }) => theme.typography.body1};
    background-color: transparent;
    border: 0;
    outline: none;
    caret-color: ${({ theme }) => theme.colorText_primary};
    -webkit-appearance: none;

    &::placeholder {
      color: ${({ theme }) => theme.colorText_body_2};
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  ${({ theme }) => theme.media.laptop} {
    height: 3.75rem;
  }
`;
