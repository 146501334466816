import { useLoginInfo } from '@hanwhalife/shared-store';
import { getScreenId } from '@hanwhalife/shared-utils/bomUtil';
import { createNciRequestHeaderInfo } from '../helper';

/**
 * NCI 기본 요청 헤더 정보를 리턴해주는 훅
 * @returns
 */
export const useNciRequestHeaderInfo = () => {
  const { loginInfo } = useLoginInfo();
  const {
    tokenType,
    accessToken,
    deviceID,
    loginType,
    platformName,
    platformVersion,
    appVer,
    carrierName,
    networkType,
    loginChannel
  } = loginInfo;

  return createNciRequestHeaderInfo({
    tokenType,
    accessToken,
    deviceID,
    loginType,
    platformName,
    platformVersion,
    appVer,
    carrierName,
    networkType,
    loginChannel,
    screenID: typeof window !== 'undefined' ? getScreenId() : ''
  });
};
