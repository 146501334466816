import { create } from '@hanwhalife/shared-libs/zustand';

interface ILoginStatusModalInfo {
  /** Login Status 모달 열림 여부 */
  showLoginStatusModal: boolean;

  /** Login 안내 필요 여부 */
  needLoginModal: boolean;

  /** 리다이렉트여부 */
  redirectUrl?: string;
}
export type UpdateLoginStatusModalInfoType = (loginStatusInfo: Partial<ILoginStatusModalInfo>) => void;

/** 모달 초기 값 */
const initialState: ILoginStatusModalInfo = {
  showLoginStatusModal: false,
  needLoginModal: false
};

interface IILoginStatusModalInfoState {
  /** LoginStatus 정보 */
  loginStatusModalInfo: ILoginStatusModalInfo;
  setLoginStatusModalInfo: UpdateLoginStatusModalInfoType;
  initLoginStatusModalInfo: () => void;
}

/**
 * LoginStatusLoginStore
 */
export const useLoginStatusModalStore = create<IILoginStatusModalInfoState>((set) => ({
  loginStatusModalInfo: initialState,
  setLoginStatusModalInfo: (loginStatusModalInfo) => {
    set((state) => ({
      loginStatusModalInfo: { ...state.loginStatusModalInfo, ...loginStatusModalInfo }
    }));
  },
  initLoginStatusModalInfo() {
    set(() => ({ loginStatusModalInfo: initialState }));
  }
}));
