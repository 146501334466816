import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { Providers } from '@/components/_shared/context/Providers';

export default function HliAppMain({ Component, pageProps }: AppProps): React.ReactElement {
  // TODO: 삭제
  useEffect(() => {
    console.log('HliAppMain is Mounted');
  }, []);

  return (
    <Providers>
      <Component {...pageProps} />
    </Providers>
  );
}
