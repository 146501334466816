import { RequestHandler, ResponseHandler, ErrorHandler } from '../http-client/HttpHandler';
import { IHttpClientOptions } from '../config/config.types';
import { useNciRequestHandler } from './useNciRequestHandler';
import { createAxiosInstance } from '../http-client/AxiosInstance';
import { HttpClient } from '../http-client/HttpClient';
import { API_BASE_URL_MAP } from '../config/config';

// HTTP 핸들러
const { handleDefaultRequest } = RequestHandler;
const { handleNciResponse, handleBoResponse, handleDefaultResponse } = ResponseHandler;
const { handleDefaultError } = ErrorHandler;

/**
 * Client - Server 통신(for client components) 객체를 리턴해주는 훅
 * @param param0
 * @returns 통신객체
 */
export const useHttpClient = ({
  instance,
  target = 'nci',
  axiosInstanceConfig,
  onRequest,
  onResponse,
  onError
}: IHttpClientOptions = {}) => {
  // nci 요청 처리 핸들러 Hook
  const onNciRequest = useNciRequestHandler();

  // axios 객체 생성
  const axiosInstance = instance ?? createAxiosInstance({ baseURL: API_BASE_URL_MAP[target], ...axiosInstanceConfig });

  // 요청 응답 intercept
  axiosInstance.interceptors.request.use(
    onRequest ?? (target === 'nci' ? onNciRequest : handleDefaultRequest),
    onError ?? handleDefaultError
  );
  axiosInstance.interceptors.response.use(
    onResponse ?? (target === 'nci' ? handleNciResponse : target === 'bo' ? handleBoResponse : handleDefaultResponse),
    onError ?? handleDefaultError
  );

  // Adaptor 적용
  return new HttpClient(axiosInstance);
};
