import React from 'react';
import { useQueryErrorResetBoundary } from '@hanwhalife/shared-libs/react-query';
import { ErrorBoundary } from '@hanwhalife/shared-libs/react-error-boundary';
import { captureException } from '@sentry/nextjs';
import { ErrorFallback, IErrorFallback } from './ErrorFallback';
import { ErrorBoundaryContext } from './context/ErrorBoundaryContext';

interface IApiErrorBoundary extends IErrorFallback {
  children: React.ReactNode;
}

/**
 * React Query 에러 catch 문으로 사용 가능한 컴포넌트
 * @param props
 * @returns
 */
export const ApiErrorBoundary = ({ children, ...rest }: IApiErrorBoundary) => {
  // 가장 가까운 에러를 reset하는 함수
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      onReset={reset}
      onError={(error) => {
        // 센트리 캡쳐
        captureException(error);
      }}
      FallbackComponent={({ error, resetErrorBoundary }) => {
        /**
         * error: 에러 정보
         * resetErrorBoundary: 에러초기화 함수
         */
        return (
          <ErrorBoundaryContext.Provider value={{ error, resetErrorBoundary }}>
            <ErrorFallback {...rest} />
          </ErrorBoundaryContext.Provider>
        );
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
