import { create } from '@hanwhalife/shared-libs/zustand';

interface IPostLoginProcessInfo {
  /** 로그인 후 원천  */
  postLoginSource?: 'login';
}
/** 초기 값 */
const initialState: IPostLoginProcessInfo = {};

interface IPostLoginProcessInfoState {
  postLoginProcessInfo: IPostLoginProcessInfo;
  setPostLoginProcessInfo: (postLoginProcessInfo: Partial<IPostLoginProcessInfo>) => void;
  initPostLoginProcessInfo: () => void;
}

/**
 * 로그인 후 처리 스토어
 */
export const usePostLoginProcessInfo = create<IPostLoginProcessInfoState>((set) => ({
  postLoginProcessInfo: initialState,
  setPostLoginProcessInfo: (postLoginProcessInfo) => {
    set((state) => ({
      postLoginProcessInfo: { ...state.postLoginProcessInfo, ...postLoginProcessInfo }
    }));
  },
  initPostLoginProcessInfo() {
    set(() => ({ postLoginProcessInfo: initialState }));
  }
}));
