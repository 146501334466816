import { NCI_CUSTOM_HEADER_KEYS, API_BASE_URL_MAP, API_HOST_MAP } from './config/config';
import { INciCustomHeader, IFetchOptions, TNciRequestHeader } from './config/config.types';

/**
 * NCI Custom Header를 생성합니다.
 * @param param0
 * @returns
 */
export const createNciRequestHeaderInfo = ({
  deviceID,
  loginType,
  screenID,
  platformName,
  platformVersion,
  loginChannel,
  appVer,
  carrierName,
  networkType,
  accessToken,
  tokenType,
  authorization
}: INciCustomHeader) => {
  const nciRequestHeaderMap: Partial<TNciRequestHeader> = {};

  // 웹 뷰의 경우에만 토큰 세팅
  if (authorization || (tokenType && accessToken)) {
    nciRequestHeaderMap[NCI_CUSTOM_HEADER_KEYS.AUTHORIZATION] = authorization ?? `${tokenType} ${accessToken}`;
  }

  // 필수
  nciRequestHeaderMap[NCI_CUSTOM_HEADER_KEYS.DEVICE_ID] = deviceID;
  nciRequestHeaderMap[NCI_CUSTOM_HEADER_KEYS.LOGIN_TYPE] = loginType;
  nciRequestHeaderMap[NCI_CUSTOM_HEADER_KEYS.SCREEN_ID] = screenID;
  nciRequestHeaderMap[NCI_CUSTOM_HEADER_KEYS.PLATFORM_NAME] = platformName;
  nciRequestHeaderMap[NCI_CUSTOM_HEADER_KEYS.PLATFORM_VERSION] = platformVersion;
  nciRequestHeaderMap[NCI_CUSTOM_HEADER_KEYS.LOGIN_CHANNEL] = loginChannel;
  nciRequestHeaderMap[NCI_CUSTOM_HEADER_KEYS.APP_VERSION] = appVer;

  // 옵션
  nciRequestHeaderMap[NCI_CUSTOM_HEADER_KEYS.CARRIER_NAME] = carrierName ?? '';
  nciRequestHeaderMap[NCI_CUSTOM_HEADER_KEYS.NETWORK_TYPE] = networkType ?? '';

  return nciRequestHeaderMap;
};

/**
 * Backend Base URL을 생성해줍니다.
 * @param options
 * @returns
 */
export const getAPIBaseUrl = (options?: IFetchOptions) => {
  const isDirectRequestToBackend = options?.isDirectRequestToBackend ?? true;
  const target = options?.target ?? 'nci';

  return isDirectRequestToBackend ? API_HOST_MAP[target] : API_BASE_URL_MAP[target];
};
