import { HttpClient, HttpFetcher as API } from '@hanwhalife/http-client';
import {
  IRefreshTokenInput,
  IRefreshTokenResponse,
  ILoginInput,
  ILoginResponse,
  ILoginOutput,
  IAuthTokenIntrospectInput,
  IAuthTokenIntrospectResponse,
  IDummyOutput,
  IDummyResponse
} from './auth.types';
import { IIntegratedAuthenticationConfirm } from '../cert/cert.types';

/**
 * 토큰 갱신 (Middleware to NCI)
 * ! Server용
 * Access Token 만료 시 Refresh Token 을 이용한 토큰 갱신
 * @param param0
 * @param options
 * @returns IRefreshTokenResponse
 */
export const updateTokenThroughRefreshTokenForServer = async (
  { refreshToken }: IRefreshTokenInput,
  options?: RequestInit
): Promise<IRefreshTokenResponse> => {
  return await API.post(
    `/cert/v1/post/auth/token/refresh`,
    {
      refreshToken,
      grantType: 'refresh_token'
    },
    {
      headers: {
        ...options?.headers
      }
    }
  );
};

/**
 * 토큰 갱신 (for client)
 * ! Client용
 * Refresh Token 을 이용한 토큰 갱신
 * @param param0
 * @param options
 * @returns IRefreshTokenResponse
 */
export const updateFreshTokenThroughRefreshToken = async (API: HttpClient): Promise<IDummyOutput> => {
  return (await API.post('/update/chopsticks')).returnData;
};

/**
 * 토큰 갱신(일정시간에 도달했을 때) (for client)
 * ! Client용
 * Access Token 만료 시 Refresh Token 을 이용한 토큰 갱신
 * @param param0
 * @param options
 * @returns IDummyOutput
 */
export const updateTokenThroughRefreshToken = async (API: HttpClient): Promise<IDummyOutput> => {
  return (await API.post('/chopsticks')).returnData;
};

/**
 * 로그인 (Middleware to NCI)
 * ! Server용
 * @param param0
 * @param options
 * @returns ILoginResponse
 */
export const loginAPIRequestForServer = async (
  { certificationInformation }: ILoginInput,
  options?: RequestInit
): Promise<ILoginResponse> => {
  return await API.post(
    `/itmr/v1/post/login-status`,
    { certificationInformation },
    {
      headers: {
        ...options?.headers
      }
    }
  );
};

/**
 * 로그인 (for client)
 * ! Client용
 * @param param0
 * @param options
 * @returns ILoginResponse
 */
export const loginAPIRequest = async (
  API: HttpClient,
  param: IIntegratedAuthenticationConfirm
): Promise<ILoginOutput> => {
  return (await API.post('/itmr/v1/post/login-status', { certificationInformation: { ...param } })).returnData;
};

/**
 * 로그아웃 (Middleware to NCI)
 * ! Server용
 * @param param0
 * @param options
 * @returns ILoginResponse
 */
export const logoutAPIRequestForServer = async (options?: RequestInit): Promise<IDummyResponse> => {
  return await API.post(`/itmr/v1/post/logout`, undefined, {
    headers: {
      ...options?.headers
    }
  });
};

/**
 * 로그아웃 (for client)
 * ! Client용
 * @param param0
 * @param options
 * @returns ILoginResponse
 */
export const logoutAPIRequest = async (API: HttpClient): Promise<IDummyOutput> => {
  return (await API.post('/itmr/v1/post/logout')).returnData;
};

/**
 * 쿠키 초기화 (for client)
 * ! Client용
 * @param param0
 * @param options
 * @returns ILoginResponse
 */
export const cookieClearRequest = async (API: HttpClient): Promise<IDummyOutput> => {
  return (await API.post('/init/chopsticks')).returnData;
};

/**
 * 토큰 검증 (Middleware to NCI)
 * ! Server용
 * Access Token, Refresh Token 검증
 * @param param0
 * @param options
 * @returns IRefreshTokenResponse
 */
export const getAuthTokenIntrospectForServer = async (
  { token, tokenTypeHint }: IAuthTokenIntrospectInput,
  options?: RequestInit
): Promise<IAuthTokenIntrospectResponse> => {
  return await API.post(
    `/cert/v1/post/auth/token/introspect`,
    {
      token,
      tokenTypeHint
    },
    {
      headers: {
        ...options?.headers
      }
    }
  );
};

/**
 * 토큰검증 (for client)
 * ! Client용
 * @param param0
 * @param options
 * @returns IDummyOutput
 */
export const getAuthTokenIntrospect = async (API: HttpClient): Promise<IDummyOutput> => {
  return (await API.post('/introspect/chop')).returnData;
};

/**
 * 토큰검증 (for client, non cookie)
 * ! Client용
 * @param param0
 * @param options
 * @returns IAuthTokenIntrospectResponse
 */
export const getAuthTokenIntrospectForNonCookie = async (
  API: HttpClient,
  { token, tokenTypeHint }: IAuthTokenIntrospectInput
): Promise<IAuthTokenIntrospectResponse> => {
  return (await API.post('/cert/v1/post/auth/token/introspect', { token, tokenTypeHint })).returnData;
};
