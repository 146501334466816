import React, { useState, useMemo, useCallback } from 'react';
import { Modal } from '@hanwhalife/design-system';

type TermChildList = {
  sectionNumber: number;
  title: string;
  childList: Array<{
    id: number;
    title: string;
    url: string;
    isChecked: boolean;
    isRequired: boolean;
    isShow: boolean;
    isMustRead: boolean;
  }>;
  isChecked: boolean;
  isShow: boolean;
};

export interface TermList {
  [key: string]: TermChildList;
}

/**
 * 약관 관련 커스텀 훅
 * @param terms
 * @returns
 */
export const useTerms = (terms: TermList) => {
  const [isTermLoading, setIsTermLoading] = useState(false);
  const [termList, setTermList] = useState(terms);
  const [selectedTermData, setSelectedTermData] = useState({
    title: '',
    url: '',
    sectionNumber: '',
    childId: -1,
    isChecked: false,
    isMustRead: false
  });

  /** 약관 상태 초기화 */
  const initiateTermList = () => {
    setTermList((prevTermList) => {
      const updatedTermsState = { ...prevTermList };

      Object.values(updatedTermsState).forEach((section) => {
        section.isChecked = false;
        section.childList.forEach((child) => {
          child.isChecked = false;
        });
      });

      return updatedTermsState;
    });
  };

  /** 현재 선택된 약관 상태 초기화 */
  const initiateSelectedTermData = useCallback(() => {
    setSelectedTermData({ title: '', url: '', sectionNumber: '', childId: -1, isChecked: false, isMustRead: false });
  }, [selectedTermData]);

  /** 전체 선택
   * TODO: 현재 isShow false인 부분도 반전되고 있음
   */
  const handleAllChange = () => {
    setTermList((prevTermList) => {
      const updatedTermsState = { ...prevTermList };

      const allSectionsChecked = Object.values(updatedTermsState).every((section) => section.isChecked);

      Object.values(updatedTermsState).forEach((section) => {
        section.isChecked = !allSectionsChecked;
        section.childList.forEach((child) => {
          if (child.isShow) {
            child.isChecked = section.isChecked;
          }
        });
      });

      return updatedTermsState;
    });
  };

  /** 대분류 선택 */
  const handleLargeCategoryChange = (sectionNumber: string) => {
    setTermList((prevTermList) => {
      const updatedTermsState = { ...prevTermList };
      const section = updatedTermsState[sectionNumber];

      section.isChecked = !section.isChecked;
      section.childList.forEach((child) => {
        if (child.isShow) {
          child.isChecked = section.isChecked;
        }
      });

      return updatedTermsState;
    });
  };

  /** 소분류 선택 */
  const handleSmallCategoryChange = (sectionNumber: string, childId: number) => {
    setTermList((prevTermList) => {
      const updatedTermsState = { ...prevTermList };
      const section = updatedTermsState[sectionNumber];
      const child = section.childList.find((c) => c.id === childId);

      if (child) {
        // 필수로 노출해야 하는 약관
        console.log(section.isChecked);
        child.isChecked = !child.isChecked;
        section.isChecked = section.childList.every((c) => !c.isShow || c.isChecked);
        if (child.isMustRead) {
          /* setSelectedTermData((cur) => ({
            ...cur,
            title: child.title,
            url: child.url,
            childId: child.id,
            sectionNumber
          })); */
        } else {
          //child.isChecked = !child.isChecked;
          //section.isChecked = section.childList.every((c) => !c.isShow || c.isChecked);
        }

        return updatedTermsState;
      }

      return prevTermList;
    });
  };

  /** 체크 되었는지 boolean 값 반환 */
  const isAllChecked = useMemo(() => {
    return Object.values(termList)
      .filter((section) => section.isShow)
      .every((section) =>
        section.childList.filter((child) => child.isShow && child.isRequired).every((child) => child.isChecked)
      );
  }, [termList]);

  const TermsfullPopup = () => {
    return (
      <Modal
        type="full-popup"
        title=""
        bodyContentsClassName="ps-0 pe-0 pb-0"
        bodyContents={
          <iframe
            src={selectedTermData.url}
            height="100%"
            width="100%"
            title={selectedTermData.title}
            className="d-block"
            onLoad={() => {
              setIsTermLoading(false);
            }}
          />
        }
        mainBtnText={'동의하기'}
        closeIconEnabled={true}
        onMainBtnClick={() => {
          if (!selectedTermData.isChecked) {
            handleSmallCategoryChange(`${selectedTermData.sectionNumber}`, selectedTermData.childId);
          }

          if (selectedTermData.isMustRead) {
            console.log('sdfs');
          }

          initiateSelectedTermData();
        }}
        onCloseClick={() => initiateSelectedTermData()}
        showModal={!!selectedTermData.title}
      />
    );
  };

  return {
    termList,
    setTermList,
    handleAllChange,
    handleLargeCategoryChange,
    handleSmallCategoryChange,
    isAllChecked,
    isTermLoading,
    setIsTermLoading,
    selectedTermData,
    setSelectedTermData,
    initiateTermList,
    initiateSelectedTermData,
    TermsfullPopup
  };
};
