import styled from '@emotion/styled';

/**
 * Faq Desktop 검색필드 하단 많이 찾는 검색어입니다.
 */
export const StyledFaqSearchWords = styled.div`
  display: none;

  ${({ theme }) => theme.media.laptop} {
    display: flex;
    gap: 0.25rem;
    margin-top: 1.25rem;

    .word {
      padding: 0;
      ${({ theme }) => theme.typography.body1}
      color: ${({ theme }) => theme.colorText_body_3};
      background: transparent;
      border: 0;
    }
  }
`;

export const StyledFaqChipsWrapper = styled.div`
  padding: 1rem 0;

  ${({ theme }) => theme.media.laptop} {
    padding: 2rem 0 3rem;

    /** //TODO: DS Chips 컴포넌트 focus 웹접근성 예외 처리
    */
    .ds-chips {
      padding: 2px 0;
    }
  }
`;

export const StyledFaqTabsWrapper = styled.div``;

export const StyledFaqTabsContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledFaqSearchFieldWrap = styled.div`
  margin-top: 1rem;

  ${({ theme }) => theme.media.laptop} {
    margin-top: 0;
    margin-bottom: 3rem;
  }
`;

export const StyledFaqSearchSubTitle = styled.h4`
  margin-top: 2rem;
  margin-bottom: 1rem;

  ${({ theme }) => theme.media.laptop} {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
`;

export const StyledFaqContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledFaqItem = styled.span`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.typography.body1}
  text-align: left;
  word-break: keep-all;

  /* direct list hover style */
  &.list-item-hover:hover {
    @media (hover: hover) and (pointer: fine) {
      .text {
        color: ${({ theme }) => theme.colorText_primary};
        transition: color 0.2s ease-out;
      }
    }
  }

  .text {
    color: ${({ theme }) => theme.colorText_body};
  }

  .word {
    /* 많이 찾는 검색어 */
    color: ${({ theme }) => theme.colorText_body_2};
  }

  .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
`;
