type ValidationKey = 'birthday-8' | 'birthday-19-75';

type IValidationMessage = {
  [key in ValidationKey]: {
    /** 에러 메시지 내용 */
    message: string;

    /** 개발자용 메시지 */
    devMessage?: string;
  };
};

/**
 * 유효성 검증 메시지들을 정의 합니다.
 */
export const validationMessage: IValidationMessage = {
  ['birthday-8']: {
    message: '생년월일을 정확히 입력해 주세요.',
    devMessage: '생년월일을 정확히 입력해 주세요.'
  },
  ['birthday-19-75']: {
    message: '만 19세~75세까지 가입할 수 있습니다.',
    devMessage: '만 19세~75세까지 가입할 수 있습니다.'
  }
};
