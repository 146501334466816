type ErrorMessageKey = '404' | 'default' | 'retry';

type IErrorMessage = {
  [key in ErrorMessageKey]: {
    /** 에러 메시지 제목 */
    title: string;

    /** 에러 메시지 내용 */
    content: string;

    /** 조치 메시지 */
    actionMessage?: string;

    /** 개발자용 메시지 */
    devMessage?: string;
  };
};

/**
 * 에러 메시지들을 정의 합니다.
 * 900번 부터는 커스텀한 메시지, 이외에는 HTTP 코드를 사용합니다.
 */
export const errorMessage: IErrorMessage = {
  ['404']: {
    title: '이용에 불편을 드려 죄송합니다',
    content:
      '주소가 올바르지 않거나 변경 혹은 삭제 되어 페이지를 찾을 수 없습니다. 입력하신 페이지의 주소를 다시 한번 확인해 주세요.',
    actionMessage: '홈페이지 바로가기'
  },
  ['default']: {
    title: '일시적인 오류로 정보를 불러올 수 없어요',
    content: '예상하지 못한 오류가 발생했어요. 도움이 필요하시면 고객센터(1588-6363)로 문의해주세요.',
    actionMessage: ''
  },
  ['retry']: {
    title: '일시적인 오류로 정보를 불러올 수 없어요',
    content: '정보를 확인하려면 다시 시도해보세요.',
    actionMessage: '다시 시도하기'
  }
};
