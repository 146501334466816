/**
 * Files
 */

// PDF
export const PDF = {
  // 보험금 청구서
  insuranceBill: 'https://file.hanwhalife.com/attach/accident/보험금청구서.pdf',
  // 보험금 청구서 작성예시
  insuranceBillEx: 'https://file.hanwhalife.com/attach/accident/formimg1_2.pdf',
  // 위임장
  warrantType1: 'https://file.hanwhalife.com/attach/accident/위임장(보험금 청구 및 수령用).pdf',
  // 위임장 (교도소재소자, 군복무자, 해외거주자용)
  warrentType2: 'https://file.hanwhalife.com/attach/accident/위임장(교도소재소자 군복무자 해외거주자용用).pdf',
  // 실손의료비 보험금 청구서류 접수 대행서비스 신청
  healthInsActService: 'https://file.hanwhalife.com/attach/accident/실손의료비_청구서류_접수대행_신청서양식.pdf',
  // 실손의료비 연대책임 신청
  healthInsSolidarity: 'https://file.hanwhalife.com/attach/accident/실손의료비_연대책임_신청서양식.pdf',
  // 치과 치료 확인서
  dentalWrarent: 'https://file.hanwhalife.com/attach/accident/치과치료 확인서.pdf',
  //특정복합부위통증증후군 진단확인서
  CRPSWrarent: 'https://file.hanwhalife.com/attach/accident/특정복합부위통증증후군_진단확인서.pdf',
  // 표적항암약물허가 치료확인서
  drugWrarrent: 'https://file.hanwhalife.com/attach/accident/표적항암약물허가치료확인서.pdf',
  // 간병인 사용 확인서
  careWrarrent: 'https://file.hanwhalife.com/attach/accident/간병인사용확인서.pdf',
  // 특정항암호르몬약물허가 치료확인서
  anticancerWrarrent: 'https://file.hanwhalife.com/attach/accident/특정항암호르몬약물허가치료확인서.pdf'
};
