import { getAPIBaseUrl } from '../helper';
import { IFetchOptions } from '../config/config.types';

/**
 * fetch API 용 (간단간단히 사용 가능, middleware에서 꼭 사용)
 */
export const HttpFetcher = {
  async get(url: URL | RequestInfo, options?: IFetchOptions) {
    return await (
      await fetch(`${getAPIBaseUrl({ ...options })}${url}`, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          ...options?.headers
        }
      })
    ).json();
  },
  async post(url: URL | RequestInfo, param?: Record<string, unknown>, options?: IFetchOptions) {
    return await (
      await fetch(`${getAPIBaseUrl({ ...options })}${url}`, {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          ...options?.headers
        },
        body: JSON.stringify(param),
        method: 'POST'
      })
    ).json();
  },
  async doPost(url: URL | RequestInfo, options?: IFetchOptions) {
    return await fetch(`${getAPIBaseUrl({ ...options })}${url}`, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options?.headers
      },
      body: options?.body,
      method: 'POST'
    });
  },
  async doGet(url: URL | RequestInfo, options?: IFetchOptions) {
    return await fetch(`${getAPIBaseUrl({ ...options })}${url}`, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options?.headers
      }
    });
  }
};
