import styled from '@hanwhalife/shared-libs/emotion/styled';

export const StyledEventChipsWrapper = styled.div`
  padding: 1rem 0;

  ${({ theme }) => theme.media.laptop} {
    padding: 0 0 3rem;

    /** //TODO: DS Chips 컴포넌트 focus 웹접근성 예외 처리
    */
    .ds-chips {
      padding: 2px 0;
    }
  }
`;

export const StyledEventListContent = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledEventList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;
  flex: 1;

  ${({ theme }) => theme.media.laptop} {
    margin-top: 0;
  }
`;

export const StyledEventListItem = styled.li`
  .event-list-item-link {
    display: flex;
    flex-direction: column;
  }

  .thumb {
    position: relative;
    min-width: 320px;
    height: auto;
    aspect-ratio: 2 / 1;
    margin-bottom: 1rem;
    overflow: hidden;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAyMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjEwIiBmaWxsPSIjRTJFNUVBIi8+CjxwYXRoIGQ9Ik04IDMuNUM4IDMuMjIzODYgOC4yMjM4NiAzIDguNSAzSDExLjVDMTEuNzc2MSAzIDEyIDMuMjIzODYgMTIgMy41VjYuNUMxMiA2Ljc3NjE0IDExLjc3NjEgNyAxMS41IDdIOC41QzguMjIzODYgNyA4IDYuNzc2MTQgOCA2LjVWMy41WiIgZmlsbD0iI0QzRDhERSIvPgo8cGF0aCBkPSJNMTAuOTk5MiA0LjRDMTAuOTk5MiA0LjYyMDkxIDEwLjgyMDEgNC44IDEwLjU5OTIgNC44QzEwLjM3ODMgNC44IDEwLjE5OTIgNC42MjA5MSAxMC4xOTkyIDQuNEMxMC4xOTkyIDQuMTc5MDkgMTAuMzc4MyA0IDEwLjU5OTIgNEMxMC44MjAxIDQgMTAuOTk5MiA0LjE3OTA5IDEwLjk5OTIgNC40WiIgZmlsbD0iI0UyRTVFQSIvPgo8cGF0aCBkPSJNMTEuMzM5IDYuNTAwNzdIOC42NjI2M0M4LjU4ODI5IDYuNTAwNzcgOC41Mzk5NCA2LjQyMjUzIDguNTczMTkgNi4zNTYwNEw5LjQyMzc2IDQuNjU0ODlDOS40NTgxIDQuNTg2MjIgOS41NTM4MiA0LjU4MDI2IDkuNTk2NDEgNC42NDQxNEwxMC40MzMyIDUuODk5MjdDMTAuNDY4MiA1Ljk1MTc4IDEwLjU0MjUgNS45NTkxMyAxMC41ODcxIDUuOTE0NTFMMTAuOTAyNyA1LjU5ODg3QzEwLjk1MSA1LjU1MDYxIDExLjAzMjMgNS41NjM4MSAxMS4wNjI5IDUuNjI0ODZMMTEuNDI4NSA2LjM1NjA0QzExLjQ2MTcgNi40MjI1MyAxMS40MTM0IDYuNTAwNzcgMTEuMzM5IDYuNTAwNzdaIiBmaWxsPSIjRTJFNUVBIi8+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: ${({ theme }) => theme.radius.borderRadius_xLarge};
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 2%);
  }

  .type {
    margin-bottom: 0.25rem;
    ${({ theme }) => theme.typography.caption}
    color:  ${({ theme }) => theme.colorText_neutral_1};
  }

  .title-wrap {
    display: flex;
    align-items: top;
    margin-bottom: 0.5rem;
  }

  .title {
    ${({ theme }) => theme.typography.subtitle2}
  }

  .icon-pin {
    margin-top: 2px;
    margin-left: 0.25rem;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &-winning {
      margin-top: 0.25rem;
      ${({ theme }) => theme.media.laptop} {
        margin-top: 0.5rem;
      }
    }
  }

  .date {
    margin-right: 0.5rem;
    ${({ theme }) => theme.typography.body2}
    color: ${({ theme }) => theme.colorText_neutral_1};
  }

  .status {
    ${({ theme }) => theme.typography.body2}
    color: ${({ theme }) => theme.colorText_primary};

    &.expected {
      color: ${({ theme }) => theme.colorText_body_1};
    }

    &::before {
      display: inline-block;
      width: 1px;
      height: 11px;
      margin-right: 0.5rem;
      content: '';
      background-color: ${({ theme }) => theme.colorBorder_3};
    }
  }

  ${({ theme }) => theme.media.laptop} {
    .event-list-item-link {
      flex-direction: row;
      align-items: center;
    }

    .thumb {
      margin-right: 2rem;
      margin-bottom: 0;
    }

    &:hover {
      .thumb {
        z-index: 2;
        box-shadow: 0 10px 22px 0 rgb(0 0 0 / 4%);
        transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
        transform: translateY(-4px);
      }

      .title {
        color: ${({ theme }) => theme.colorText_primary};
        transition: color 0.2s ease-out;
      }
    }
  }
`;
