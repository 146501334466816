import React from 'react';
import { IGlobal_Modal, IModalEntity } from '@reactjs/components/_shared/context/global-ui/global-ui.types';

/**
 * API 에러 컨텐츠
 * @param modal
 * @returns
 */
export const getApiRequestErrorContents = (modal: IGlobal_Modal): IModalEntity => ({
  title: '알림',
  bodyContents: (
    <>
      서버와 통신하는데 문제가 발생했어요.
      <br />
      다시 시도하시거나 같은 문제가 반복되면 고객센터(
      <a href="tel:1588-6363" className="text-underline">
        1588-6363
      </a>
      )로 문의해주세요
    </>
  ),
  mainBtnText: '확인',
  onMainBtnClick() {
    modal.closeModal();
  }
});

/**
 * 못된 경로 접근 에러
 */
export const getInvalidAccessErrorContents = (modal: IGlobal_Modal): IModalEntity => ({
  type: 'dialog',
  title: '알림',
  bodyContents: (
    <>
      잘못된 경로로 접근하여 처음부터 다시 진행해야해요.
      <br />
      같은 문제가 반복되면 고객센터(
      <a href="tel:1588-6363" className="text-underline">
        1588-6363
      </a>
      )로 문의해주세요
    </>
  ),
  onMainBtnClick() {
    modal.closeModal();
  }
});
