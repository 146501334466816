import React from 'react';
import { useLoginInfo } from '@hanwhalife/shared-store';

interface IAppLoginCheck {
  /** 로그인 필수 여부 */
  requiredLogin: boolean;

  /** 로그인 페이지로 리다이렉트 여부 */
  redirectLoginPage?: boolean;

  /** 리다이렉트 URL */
  redirectUrl?: string;

  /** 자식 컴포넌트 */
  children: React.ReactNode;
}

export const AppLoginCheck = ({ requiredLogin, children }: IAppLoginCheck) => {
  const { loginInfo } = useLoginInfo();

  // TODO: Redirect 처리는 DTC회원 체계 구축 후 보완키로
  if ((requiredLogin && loginInfo.isLogined) || !requiredLogin) {
    return <>{children}</>;
  }

  // 미로그인시
  return <></>;
};
