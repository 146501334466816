export * from './ageUtil';
export * from './bomUtil';
export * from './dateUtil';
export * from './domUtil';
export * from './regexpUtils';
export * from './jsxUtil';
export * from './stringUtil';
export * from './urlUtil';
export * from './validationUtil';
export * from './windowUtil';
export * from './asyncUtil';
export * from './eventUtil';
export * from './htmlUtil';
export * from './cryptoUtil';
export * from './codeUtil';

declare global {
  interface Window {
    dataLayer?: object[];
  }
}
