import styled from '@hanwhalife/shared-libs/emotion/styled';

export const StyledNoticeChipsWrapper = styled.div`
  padding: 1rem 0;

  ${({ theme }) => theme.media.laptop} {
    padding: 0 0 3rem;

    /** //TODO: DS Chips 컴포넌트 focus 웹접근성 예외 처리
    */
    .ds-chips {
      padding: 2px 0;
    }
  }
`;

export const StyledNoticeContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledNoticeItem = styled.span`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colorText_body};
  text-align: left;

  /* direct list hover style */
  &.list-item-hover:hover {
    @media (hover: hover) and (pointer: fine) {
      color: ${({ theme }) => theme.colorText_primary};
      transition: color 0.2s ease-out;
    }
  }

  .text-wrap {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.25rem;
  }

  .title {
    display: flex;
    gap: 0.25rem;
    align-items: top;
    ${({ theme }) => theme.typography.subtitle2}
    word-break: keep-all;
  }

  .date {
    ${({ theme }) => theme.typography.body2}
    color: ${({ theme }) => theme.colorText_body_3};
  }

  .icon-pin {
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 2px;
  }
`;

/* 공지사항 & FAQ 상세화면 style */
export const StyledDetailTitle = styled.div`
  padding: 1rem 0 2rem;

  .detail-title {
    ${({ theme }) => theme.typography.title3}
    word-break: keep-all;
  }

  .detail-date {
    display: inline-block;
    ${({ theme }) => theme.typography.body2}
    color: ${({ theme }) => theme.colorText_body_2};
  }

  ${({ theme }) => theme.media.laptop} {
    padding-top: 0;
  }
`;

export const StyledDetailContent = styled.div`
  min-height: 240px;
`;

export const StyledRichTexts = styled.div`
  ${({ theme }) => theme.typography.body1}
  color: ${({ theme }) => theme.colorText_body_1};
  word-break: keep-all;
  overflow-wrap: break-word;

  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    margin: revert;
  }

  p {
    margin: revert;
  }

  ol,
  ul {
    list-style: revert;
  }

  ol,
  ul,
  li {
    padding: revert;
    margin: revert;
  }

  a {
    color: -webkit-link;
    text-decoration: underline;
  }

  blockquote {
    padding-left: 1rem;
    margin-left: 1.5rem;
    border-left: 2px solid #ccc;
  }

  img {
    height: auto;
  }
`;

export const StyledInfinityScrollSpinnerInner = styled.div`
  height: 2rem;
  margin-top: 1rem;
`;
