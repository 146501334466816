import { HTMLAttributes, useState } from 'react';
import Link from 'next/link';
import { Button, IconArrowDown, IconArrowUp, Tooltip } from '@hanwhalife/design-system';
import {
  StyledFooter,
  StyledFooterContainer,
  StyledFooterDropdown,
  StyledFooterDropdownItem,
  StyledFooterDropdownOverlay,
  StyledFooterNav
} from './Footer.styled';
import data from './data';
import { HLI_CDN_STATIC, MODE } from '@hlp-frontend/shared-constants';
import { useHlpRouter } from '@reactjs/router';
import { useMounted } from '@reactjs/hooks';

export function Footer({ className }: HTMLAttributes<HTMLDivElement>): JSX.Element {
  const router = useHlpRouter();
  const mounted = useMounted();
  const [isDropdown, setIsDropdown] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const handleDropdown = (value: boolean) => {
    setIsDropdown(value);
  };

  const handleTooltipShow = (isShow?: boolean) => {
    setIsShow(isShow ?? false);
  };

  return (
    <>
      {mounted && (
        <StyledFooter className={className}>
          <StyledFooterContainer>
            <StyledFooterNav>
              {data[MODE === 'local' ? 'stage' : MODE ?? 'prod'].fnbMenu.map(({ menuUrl, menuName }, idx) => (
                <Link
                  key={idx}
                  href={router.getLink(menuUrl)}
                  className={`${menuName === '개인정보처리방침' && 'fw-bold'}`}
                  target={router.getTarget(menuUrl)}
                >
                  {menuName}
                </Link>
              ))}
            </StyledFooterNav>
            <div className="company-container">
              <div className="content">
                <div className="company">
                  <div className="company-item">한화생명보험 주식회사</div>
                  <div className="company-item">사업자등록번호 116-81-11757</div>
                  <div className="company-item">대표이사 여승주</div>
                </div>
                <div className="address-wrap">
                  <address className="address">주소 07345 서울시 영등포구 63로 50 (여의도동, 63 한화생명빌딩)</address>
                  <p className="address-tel">대표번호1588-6363</p>
                </div>
                <div className="copyright">Ⓒ HANWHA LIFE Corp. All Rights Reserved.</div>
              </div>
              <div className="tel">
                <p className="tel-title">다이렉트 가입문의</p>
                <p className="tel-number">1544-6385</p>
              </div>
            </div>
            <div className="ff-container">
              <div className="mark-wrap">
                <div className="mark wa">
                  <a
                    href="http://www.wa.or.kr/"
                    target="_blank"
                    title="새창열림"
                    className="mark-image"
                    rel="noreferrer"
                  >
                    <img
                      src={`${HLI_CDN_STATIC}/direct/content-footer/wa-mark.png`}
                      width={62}
                      height={42}
                      alt="(사)한국장애인단체총연합회 한국웹접근성인증평가원 웹 접근성 우수사이트 인증마크(WA인증마크)"
                    />
                  </a>
                  <div className="mark-text">
                    웹 접근성 인증마크
                    <br />
                    10년 연속 획득
                  </div>
                </div>
                <div className="mark isms-p">
                  <Tooltip
                    toolTipTail="topCenter"
                    toolTipText={
                      <>
                        정보보호 및 개인 정보관리체계 인증 <br />
                        인증범위 : 대표 홈페이지(보험서비스)/ <br />
                        모바일 앱 및 다이렉트 보험(GA 채널 제외) <br />
                        (심사받지 않은 물리적 인프라 제외) <br />
                        유효기간 : 2023.11.29 ~ 2026.11.28
                      </>
                    }
                    className="mark-tooltip"
                    isShow={isShow}
                  >
                    <button
                      type="button"
                      onMouseEnter={() => handleTooltipShow(true)}
                      onMouseLeave={() => handleTooltipShow(false)}
                      onFocus={() => handleTooltipShow(true)}
                      onBlur={() => handleTooltipShow(false)}
                      aria-label={
                        '정보보호 및 개인 정보관리체계 인증 인증범위 : 대표 홈페이지(보험서비스)/ 모바일 앱 및 다이렉트 보험(GA 채널 제외) (심사받지 않은 물리적 인프라 제외) 유효기간 : 2023.11.29 ~ 2026.11.28'
                      }
                      className="mark-image"
                    >
                      <img
                        src={`${HLI_CDN_STATIC}/direct/content-footer/isms-p-mark.png`}
                        width={62}
                        height={42}
                        alt="ISMS-P 인증마크"
                      />
                    </button>
                  </Tooltip>

                  <div className="mark-text">
                    개인정보보호
                    <br />
                    관리체계 인증
                  </div>
                </div>
              </div>

              <StyledFooterDropdown className={isDropdown ? 'opened' : undefined}>
                <StyledFooterDropdownOverlay
                  onClick={() => handleDropdown(false)}
                  className={isDropdown ? 'opened' : undefined}
                />
                <Button
                  type="neutral1"
                  size="small"
                  iconDirect="right"
                  className="dropdown-button"
                  icon={() => (isDropdown ? <IconArrowUp fill={'#737C85'} /> : <IconArrowDown fill={'#737C85'} />)}
                  onClick={() => handleDropdown(!isDropdown)}
                >
                  Family Site
                </Button>
                <div className="dropdown-content">
                  {data.familySite.map(({ menuUrl, menuName }, index) => (
                    <StyledFooterDropdownItem
                      key={index}
                      href={router.getLink(menuUrl)}
                      target={router.getTarget(menuUrl)}
                      title={router.getTitle(menuUrl)}
                    >
                      {menuName}
                    </StyledFooterDropdownItem>
                  ))}
                </div>
              </StyledFooterDropdown>
            </div>
          </StyledFooterContainer>
        </StyledFooter>
      )}
    </>
  );
}
