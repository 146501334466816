export const Spacing = {
  Sx3: '0.25rem', // 4px
  Sx2: '0.5rem', // 8px
  Sx: '0.75rem', // 12px
  S: '1rem', // 16px
  M: '1.25rem', // 20px
  L: '1.5rem', // 24px
  Lx: '1.75rem', // 28px
  Lx2: '2rem', // 32px
  Lx3: '3rem', // 48px
  Lx4: '4rem', // 64px
  Lx5: '4.5rem' // 72px
};

/**
 * desktop 해상도 max-width 입니다.
 */
export const maxWidthDesktop = '1176px';

/**
 * desktop 해상도 center layout max-width 입니다.
 */
export const maxWidthDesktopCenter = '552px';

/**
 * desktop 해상도에서 사용하는 radius 입니다.
 */
export const borderRadiusDesktop = '1.25rem';

/**
 * 콘텐츠 영역 상하단 공통 스페이싱
 */
export const contentVerticalPadding = `${Spacing.Lx2} 0`;
export const contentVerticalDesktopPadding = `${Spacing.Lx2} 0 ${Spacing.Lx4}`;
