import React, { createContext, Dispatch, SetStateAction, useState, ReactNode, RefObject, createRef } from 'react';
import { createPortal } from 'react-dom';

export interface IPortalContext {
  containers?: Record<string, RefObject<HTMLDivElement> | null>;
  setContainer?: Dispatch<SetStateAction<Record<string, RefObject<HTMLDivElement> | null>>>;
}
export const PortalContext = createContext<IPortalContext>({});

export function PortalProvider({ children }: { children: ReactNode }): JSX.Element {
  const [refObjs, setRefObjs] = useState<Record<string, RefObject<HTMLDivElement> | null>>({
    portal: createRef<HTMLDivElement>()
  });

  return (
    <PortalContext.Provider value={{ containers: refObjs, setContainer: setRefObjs }}>
      {children}
      <div id="portal-container" ref={refObjs['portal']} />
    </PortalContext.Provider>
  );
}

export function PortalConsumer({
  containerId = 'portal',
  children
}: {
  containerId?: string;
  children: ReactNode;
}): JSX.Element {
  return (
    <PortalContext.Consumer>
      {({ containers }) => {
        if (containers && containers[containerId]?.current) {
          return createPortal(children, containers[containerId]?.current as HTMLDivElement);
        }
      }}
    </PortalContext.Consumer>
  );
}
