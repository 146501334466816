import replace from 'lodash/replace';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { convertDateFormat } from './stringUtil';

/*****************************
 * 날짜 관련 유틸
 ******************************/

dayjs.extend(weekOfYear);
dayjs.locale('ko');

/**
 *
 * @param year
 * @param month
 * @returns
 */
export function getLastDay(year: number, month: number) {
  switch (month) {
    case 2:
      return year % 4 === 0 ? 29 : 28;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    default:
      return 31;
  }
}

/**
 * 한달뒤 날짜를 포맷형태로 리턴
 * @param 20200101 or 2020.01.01
 * @param 'YYYY.MM.DD'
 * @returns
 */
export const getOneMonthLater = (value?: string, format?: string): string => {
  const str = value ? value.split('.').join('') : '';
  const date = value
    ? new Date(Number(str.substring(0, 4)), Number(str.substring(4, 6)) - 1, Number(str.substring(6, 8)))
    : new Date();
  try {
    return dayjs(new Date(date.setMonth(date.getMonth() + 1))).format(format || 'YYYY.MM.DD');
  } catch (err) {
    console.warn(err);

    return '';
  }
};

/**
 * 날짜 차이를 구한다. 포맷에 맞지 않으면 Not a Number 리턴
 * @param date1 (yyyymmdd or yyyy.mm.dd or yyyy-mm-dd)
 * @param date2 (yyyymmdd or yyyy.mm.dd or yyyy-mm-dd)
 * @returns date1 - date2
 */
export const getDateDiff = (date1: string, date2: string): number => {
  // 포맷 해제
  const yyyyMMdd1 = replace(date1, /[.|-]/g, '');
  const yyyyMMdd2 = replace(date2, /[.|-]/g, '');

  // dayjs object로 변환
  const diff1 = dayjs(dayjs(yyyyMMdd1).format('YYYY-MM-DD'));
  const diff2 = dayjs(yyyyMMdd2).format('YYYY-MM-DD');

  /** diff1 - diff2: 현재: 0 */
  return diff1.diff(diff2, 'day');
};

/**
 * 입력되어진 생년월일 값이 당일 기준으로 미래시점인 경우 false를 반환합니다. (회원체계 기준 9, 0 일 경우 오류)
 * @param front 주민등록번호 앞 6자리
 * @param back 주민등록번호 뒤 첫자리
 * @returns boolean
 */
export const validateBirthdate = (front: string, back: string) => {
  const year = parseInt(front.slice(0, 2));
  const month = parseInt(front.slice(2, 4));
  const day = parseInt(front.slice(4, 6));
  let birthYear = 0;

  switch (back) {
    case '1':
    case '2':
    case '5':
    case '6':
      birthYear = 1900 + year;
      break;
    case '3':
    case '4':
    case '7':
    case '8':
      birthYear = 2000 + year;
      break;
    case '9':
    case '0':
      // birthYear = 1800 + year;
      return false;
    default:
      return false;
  }

  const birthdate = new Date(birthYear, month - 1, day);
  const currdate = new Date();

  return birthdate < currdate;
};

/**
 * 목표 날짜가 비교 날짜 이후 인지 확인하는 함수 (YYYYMMDD 형식)
 * @param targetDate
 * @param compareDate
 * @returns boolean
 */
export function isDateAfter(targetDate: string, compareDate: string) {
  const targetDateTime = new Date(convertDateFormat(targetDate, 'YYYY-MM-DD')).getTime();
  const compareDateTime = new Date(convertDateFormat(compareDate, 'YYYY-MM-DD')).getTime();

  return targetDateTime > compareDateTime;
}

/**
 * "YYYYMM" 형식의 날짜 문자열을 "YYYY. M" 형식으로 변환한다.
 *
 * @param input - "YYYYMM"
 * @returns "YYYY. M"
 */
export function formatYearMonth(input: string) {
  const date = dayjs(input, 'YYYYMM');

  return date.format('YYYY. M');
}

/**
 * 오늘 날짜를 지정된 형식으로 반환한다.
 *
 * @param format - 날짜를 포맷할 형식 문자열, 기본값은 'YYYY.MM.DD'
 * @returns 오늘 날짜를 지정된 형식으로 변환한 문자열.
 */
export function getToday(format = 'YYYY. M. D') {
  return dayjs().format(format);
}

/**
 * 문자열 형식의 날짜를 지정된 구분 기호(flag)를 사용하여 포멧팅한다.
 *
 * @param num - 날짜를 나타내는 문자열 (예: "20231028", "20231028123045").
 * @param flag - 날짜 구분 기호로 사용할 문자
 * @returns 지정된 형식으로 포맷된 날짜 문자열. num이 유효하지 않으면 그대로 반환.
 */
export function formatDateString(num: string, flag = '.') {
  if (typeof num !== 'string') {
    return num;
  }

  const formatMap: { [key: number]: string } = {
    6: `YYYY${flag} M`,
    8: `YYYY${flag} M${flag} D`,
    14: `YYYY${flag} M${flag} D HH:mm:ss`
  };

  const format = formatMap[num.length];

  return format ? dayjs(num, 'YYYYMMDDHHmmss'.slice(0, num.length)).format(format) : num;
}

/**
 * 두 날짜 간의 차이를 일 수로 반환한다.
 *
 * @param _date1 - 비교할 첫 번째 날짜
 * @param _date2 - 비교할 두 번째 날짜
 * @returns 두 날짜 간의 차이 일 수.
 */
export function dateDiff(_date1: string | Date, _date2: string | Date) {
  const date1 = dayjs(_date1);
  const date2 = dayjs(_date2);

  return date1.diff(date2, 'day');
}

/**
 * 현재 날짜를 지정된 형식으로 반환한다.
 *
 * @param format - 날짜 구분 기호 (기본값: "").
 * @returns 형식화된 현재 날짜 문자열
 */
export function toDay(format = '') {
  return dayjs().format(`YYYY${format}MM${format}DD`);
}

/**
 * 주어진 날짜가 현재 날짜보다 이전인지 확인하여 Y/N 반환힌디.
 *
 * @param dateStr - 비교할 날짜 문자열
 * @returns 날짜가 현재 날짜와 같거나 이후면 "Y", 이전이면 "N".
 */
export function annuityStartYn(dateStr: string) {
  const inputDate = dayjs(dateStr, 'YYYYMMDD').format('YYYY-MM-DD');

  return dateDiff(toDay('-'), inputDate) >= 0 ? 'Y' : 'N';
}
