import { InternalAxiosRequestConfig } from '@hanwhalife/shared-libs/axios';
import { useNciRequestHeaderInfo } from './useNciRequestHeaderInfo';

/**
 * NCI 기본 요청 핸들러를 리턴해주는 훅(for client)
 * @returns
 */
export const useNciRequestHandler = () => {
  const requestHeaderInfo = useNciRequestHeaderInfo();

  /**
   * Axios Request Interceptor
   * @param reqConfig
   * @returns InternalAxiosRequestConfig
   */
  const handleNciRequest = (reqConfig: InternalAxiosRequestConfig) => {
    console.dir('요청 Config::', reqConfig);

    for (const [key, value] of Object.entries(requestHeaderInfo)) {
      reqConfig.headers.set(key, value);
    }

    return reqConfig;
  };

  return handleNciRequest;
};
