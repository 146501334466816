import { CSSProperties } from 'react';
import styled from '@hanwhalife/shared-libs/emotion/styled';
import { Spacing, maxWidthDesktop, maxWidthDesktopCenter } from '@styled/_shared/theme';

export type ChannelType = 'direct' | 'int' | 'mi';

export type AlignType = 'default' | 'center';

/**
 * 콘텐츠 영역 최상위 래퍼
 * channelType 에 따라서 스타일 분기 처리 가능
 */
export const StyledRoot = styled.div<{ channelType?: ChannelType }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;

  .fill-feedback,
  .fill-content {
    flex: 1;
  }
`;

export const StyledContent = styled.div<{ bgColor?: CSSProperties['backgroundColor'] }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${Spacing.S} 0 ${Spacing.Lx2};
  background-color: ${({ bgColor }) => bgColor && bgColor};

  ${({ theme }) => theme.media.laptop} {
    flex: none;
    padding: ${Spacing.Lx4} 0;

    /* 하단 CTA 콘텐츠 푸터 간격 처리 */
    :has(+ .content-footer) {
      padding: ${Spacing.Lx4} 0 ${Spacing.Lx2};
    }

    &:has(.fill-feedback),
    &:has(.fill-content) {
      display: flex;
      flex: 1;
    }
  }
`;

export const StyledTitle = styled.div`
  padding: 0 0 ${Spacing.Lx2};
`;

export const StyledSection = styled.section<{ bgColor?: CSSProperties['backgroundColor'] }>`
  background-color: ${({ bgColor }) => bgColor && bgColor};

  &:has(.fill-feedback),
  &:has(.fill-content) {
    display: flex;
    flex: 1;
  }
`;

export const StyledInner = styled.div<{ alignType?: AlignType }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${Spacing.M};
  margin: 0 auto;

  ${({ theme }) => theme.media.laptop} {
    max-width: ${({ alignType }) => (alignType === 'center' ? maxWidthDesktopCenter : maxWidthDesktop)};
    padding: ${({ alignType }) => (alignType === 'center' ? '0' : `0 ${Spacing.L}`)};
  }
`;

export const StyledContentFooter = styled.div<{ alignType?: AlignType }>`
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zindex.level_1};
  width: 100%;
  padding: 0 ${Spacing.M} ${Spacing.Lx2};
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colorBackground_0};

  &::before {
    position: absolute;
    top: -1.25rem;
    left: 0;
    width: 100%;
    height: 1.25rem;
    content: '';
    background: linear-gradient(0deg, #fff.5%, rgb(255 255 255 / 0%) 100%);
  }

  ${({ theme }) => theme.media.laptop} {
    position: static;
    max-width: ${({ alignType }) => (alignType === 'center' ? maxWidthDesktopCenter : maxWidthDesktop)};
    padding: ${({ alignType }) => (alignType === 'center' ? `0 0 ${Spacing.Lx4}` : `0 ${Spacing.L} ${Spacing.Lx4}`)};

    &::before {
      display: none;
    }

    /* pc 해상도에서도 하단 고정을 유지 */
    &.pc-fixed-footer {
      position: sticky;

      &::before {
        display: block;
      }
    }

    &.pc-spacing-lx2 {
      padding-top: ${Spacing.Lx2};
    }
  }
`;
