import styled from '@hanwhalife/shared-libs/emotion/styled';

export const StyledCAButtonWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem 0.625rem;
  margin-bottom: 3rem;

  .ca-button {
    width: 100%;
    padding: 1rem 0.25rem;
    border-radius: ${({ theme }) => theme.radius.borderRadius_xLarge};
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 6%);
  }
`;

export const StyledSimpleAuthInfoList = styled.ol``;

export const StyledSimpleAuthInfoListItem = styled.li`
  position: relative;
  display: flex;
  gap: 0.75rem;
  align-items: flex-start;
  padding-bottom: 2rem;

  &::before {
    position: absolute;
    top: 0;
    left: 22px;
    z-index: 1;
    display: block;
    width: 2px;
    height: 100%;
    content: '';
    background-color: #f5f6f7;
  }

  &:last-child {
    padding-bottom: 0;

    &::before {
      display: none;
    }
  }

  .icon-box {
    z-index: 1;
    flex-shrink: 0;
  }

  .title {
    ${({ theme }) => theme.typography.subtitle2}
  }

  .description {
    margin-top: 0.25rem;
    color: ${({ theme }) => theme.colorText_body_2};
    ${({ theme }) => theme.typography.body2}
  }
`;
