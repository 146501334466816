import { getUserAgent, create_UUID } from '@hanwhalife/shared-utils';
import { INativeRequestProps, INativeResponseProps } from './bridge.types';
import { CommonPlugin } from './plugins/common';
import { WalletPlugin } from './plugins/wallet';

/**
 * 웹 - 앱 인터페이스
 */
class Bridge {
  /**
   * params 객체를 인코딩한다.
   * @param params: INatvieProps
   * @returns: encode data
   */
  encodeData(callbackScriptName: string, params: INativeRequestProps): string {
    const enData = Buffer.from(
      encodeURIComponent(
        JSON.stringify({
          ...params,
          callbackScriptName
        })
      )
    ).toString('base64');

    return enData;
  }

  /**
   * encoding된 문자열을 디코딩한다.
   * @param params
   * @returns
   */
  decodeData(params: string): string {
    return decodeURIComponent(params);
  }

  /**
   * 디코딩된 문자열을 입력으로 받고 안드로이드 네이티브를 호출한다.
   * @param strParams
   */
  callAndroid(strParams: string): void {
    window.AndroidBridge.callNativeMethod(`native://callNative?${strParams}`);
  }

  /**
   * 디코딩된 문자열을 입력으로 받고 iOS 네이티브를 호출한다.
   * @param strParams
   */
  callIos(strParams: string): void {
    window.webkit.messageHandlers.callNative.postMessage(strParams);
  }

  /**
   * 콜백 함수를 생성합니다.
   * @param bridge
   * @param cbId
   * @param keepAlive
   * @param params
   * @param resolve
   * @param reject
   */
  makeCallback(
    bridge: Bridge,
    cbId: string,
    keepAlive: boolean,
    params: INativeRequestProps,
    resolve: (value: INativeResponseProps | PromiseLike<INativeResponseProps>) => void,
    reject: (reason?: any) => void,
    successCallback?: (value: INativeResponseProps) => void,
    failCallback?: (value: INativeResponseProps) => void
  ) {
    window.bridgeInterface = {
      ...window.bridgeInterface,

      // callback function 생성
      [cbId](nativeData: string) {
        // 유지할 필요가 없을 경우
        if (!keepAlive) {
          delete window.bridgeInterface[cbId];
        }

        try {
          const decodedData = bridge.decodeData(nativeData);
          const response = JSON.parse(decodedData) as INativeResponseProps;
          const { resCode, resData, resMessage } = response;

          console.info(`[bridge::info]response::${params.pluginId}-${params.command}::`, resCode, resData, resMessage);

          if (resCode === '0000') {
            // success
            resolve(response);

            successCallback?.(response);
          } else {
            // fail
            reject(response);

            failCallback?.(response);
          }
        } catch (error) {
          console.error(error);

          const errorObj = {
            resCode: '',
            resData: '',
            resMessage: 'An error has occurred.'
          };
          reject(errorObj);

          failCallback?.(errorObj);
        }
      }
    };
  }

  /**
   * 네이티브를 호출한다.
   * @param param0
   */
  callToNative(
    params: INativeRequestProps,
    keepAlive = false,
    callbackId?: string,
    successCallback?: (value: INativeResponseProps) => void,
    failCallback?: (value: INativeResponseProps) => void
  ): Promise<INativeResponseProps> {
    const cbId = callbackId ?? create_UUID();
    const bridge = new Bridge();

    // 프로미스
    return new Promise((resolve, reject) => {
      // callback 생성
      bridge.makeCallback(bridge, cbId, keepAlive, params, resolve, reject, successCallback, failCallback);

      console.info('[bridge::info]-bridgeInterface::', window.bridgeInterface);

      // 네이티브 호출
      try {
        // 파라미터 인코딩
        const encodeParams = bridge.encodeData(`window.bridgeInterface.${cbId}`, params);

        switch (getUserAgent()) {
          case 'Android':
            bridge.callAndroid(encodeParams);
            break;
          case 'iOS':
            bridge.callIos(encodeParams);
            break;
          default:
            console.log('[bridge::info] Native Calls are not supported');
            break;
        }
      } catch (error) {
        console.error(error);
        reject({ resCode: '', resData: '', resMessage: 'An error occurred during callToNative function.' });
      }
    });
  }
}

export const bridgeInstance = new Bridge();

class AppBridge {}
Object.assign(AppBridge.prototype, CommonPlugin);
Object.assign(AppBridge.prototype, WalletPlugin);

export const bridge = new AppBridge() as typeof CommonPlugin & typeof WalletPlugin;

declare global {
  interface Window {
    AndroidBridge: { callNativeMethod: (param: string) => void };
    webkit: {
      messageHandlers: {
        callNative: {
          postMessage: (param: string) => void;
        };
      };
    };
    bridgeInterface: any;
  }
}
