import React, { useState, useEffect, useRef, useCallback } from 'react';

interface TimerProps {
  minutes: number;
  seconds: number;
}
export const useTimer = ({ minutes, seconds }: TimerProps) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [time, setTime] = useState({ minutes, seconds });
  const [isExpired, setIsExpired] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const startTimer = useCallback(() => {
    setIsRunning(true);
  }, []);

  const stopTimer = useCallback(() => {
    setIsRunning(false);
  }, []);

  const resetTimer = useCallback(() => {
    setIsRunning(false);
    setIsExpired(false);
    setTime({ minutes, seconds });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        if (time.seconds > 0) {
          setTime({ ...time, seconds: time.seconds - 1 });
        }
        if (time.seconds === 0) {
          if (time.minutes === 0) {
            clearInterval(intervalRef.current as NodeJS.Timeout);
            setIsExpired(true);
          } else {
            setTime({ minutes: time.minutes - 1, seconds: 59 });
          }
        }
      }, 1000);
    }

    return () => clearInterval(intervalRef.current as NodeJS.Timeout);
  }, [isRunning, time]);

  const timer = `${time.minutes}:${time.seconds < 10 ? `0${time.seconds}` : time.seconds}`;

  return { timer, startTimer, stopTimer, resetTimer, isExpired };
};
