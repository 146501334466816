/**
 * 성별코드
 */
export const genderCode = {
  '1': '남성',
  '2': '여성'
};

/**
 * 주민등록번호 뒷자리 첫번째 숫자
 */
export const rrnBackOfFirstNum = {
  '1': '1900년대 출생한 남성',
  '2': '1900년대 출생한 여성',
  '3': '2000년대 출생한 남성',
  '4': '2000년대 출생한 남성',
  '5': '1900년대 출생한 외국인 남성',
  '6': '1900년대 출생한 외국인 남성',
  '7': '1900년대 출생한 외국인 남성',
  '8': '1900년대 출생한 외국인 남성',
  '9': '1800년대 출생한 남성',
  '0': '1800년대 출생한 남성'
};

/**
 * 통신사 코드
 */
export const telecomDivisionCode = {
  '1': 'SKT',
  '2': 'KT',
  '3': 'LG U+',
  '5': 'SKT 알뜰폰',
  '6': 'KT 알뜰폰',
  '7': 'LG U+ 알뜰폰'
} as const;

/**
 * 운전 면허증 지역 코드
 */
export const driverlicenseRegionCode = [
  { key: '11', value: '11(서울)' },
  { key: '12', value: '12(부산)' },
  { key: '13', value: '13(경기)' },
  { key: '14', value: '14(강원)' },
  { key: '15', value: '15(충북)' },
  { key: '16', value: '16(충남)' },
  { key: '17', value: '17(전북)' },
  { key: '18', value: '18(전남)' },
  { key: '19', value: '19(경북)' },
  { key: '20', value: '20(경남)' },
  { key: '21', value: '21(제주)' },
  { key: '22', value: '22(대구)' },
  { key: '23', value: '23(인천)' },
  { key: '24', value: '24(광주)' },
  { key: '25', value: '25(대전)' },
  { key: '26', value: '26(울산)' },
  { key: '28', value: '28(경기북부)' }
];

/**
 * 지역번호
 */
export const regionTelCode = [
  { key: '02', value: '02' },
  { key: '031', value: '031' },
  { key: '032', value: '032' },
  { key: '033', value: '033' },
  { key: '041', value: '041' },
  { key: '042', value: '042' },
  { key: '043', value: '043' },
  { key: '044', value: '044' },
  { key: '051', value: '051' },
  { key: '052', value: '052' },
  { key: '053', value: '053' },
  { key: '054', value: '054' },
  { key: '055', value: '055' },
  { key: '061', value: '061' },
  { key: '062', value: '062' },
  { key: '063', value: '063' },
  { key: '064', value: '064' },
  { key: '0502', value: '0502' },
  { key: '0505', value: '0505' },
  { key: '070', value: '070' },
  { key: '010', value: '010' }
];

/**
 * 문서유형 코드 맵
 * 문서서식코드 IT21: 사고이미지접수(사고보험금 청구서) IT23: 청구부속서류(보험금청구, 기타서류 이미지) IT903: 장기요양신청서 IT904: 산정특례신청서 IT757: 신분증 FC212: 소득증빙서(융자) FC201: 본인관계 확인서류(스마트 대출신분증촬영)
 */
export const documentTypeCodeMap = {
  IT21: 'IJ020001',
  IT23: 'PA012001',
  IT903: 'PA080001',
  IT904: 'PA081001',
  IT757: 'LR066001',
  FC212: 'FC017001',
  FC201: 'FC006001'
};

/**
 * 사고관계자 코드 맵
 */
export const accidentRelatedCodeMap = {
  '01': '본인',
  '02': '배우자',
  '03': '부모',
  '04': '자녀',
  '05': '형제, 자매',
  '06': '조부모',
  '10': '기타',
  '50': '배우자부모',
  '51': '당사FP',
  '52': '타사FP',
  '53': '대행업체',
  '54': '내근',
  '55': '콜센터',
  '56': 'ASM',
  '57': '고객담당',
  '60': '손해사정업체'
};
