import React, { ComponentProps } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

/**
 * Suspense시  catch 문으로 사용 가능한 컴포넌트
 * @param props
 * @returns
 */
export const AppErrorBoundary = ({ children, ...rest }: ComponentProps<typeof ReactErrorBoundary>) => {
  return <ReactErrorBoundary {...rest}>{children}</ReactErrorBoundary>;
};
