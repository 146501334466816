import { useContext } from 'react';
import { ErrorBoundaryContext } from '../context/ErrorBoundaryContext';

/**
 * Error boundary에 Catch된 에러 객체와 reset할 수 있는 함수를 참조할 수 있습니다.
 * @returns FallbackProps (error, resetErrorBoundary)
 */
export const useErrorBoundaryContext = () => {
  const context = useContext(ErrorBoundaryContext);

  return context;
};
