import { create } from '@hanwhalife/shared-libs/zustand';

/**
 * Spinner
 */
interface ISpinner {
  isActive: boolean;
  showSpinner: (duration?: number) => void;
  hideSpinner: () => void;
}

export const useSpinnerStore = create<ISpinner>((set) => ({
  isActive: false,
  showSpinner: (duration) => {
    if (duration) {
      const timer = setTimeout(() => {
        set(() => ({ isActive: false }));
        clearTimeout(timer);
      }, duration);
    }

    set(() => ({ isActive: true }));
  },
  hideSpinner: () => {
    set(() => ({ isActive: false }));
  }
}));
