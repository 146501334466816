import { useState, useEffect } from 'react';

/**
 * 컴포넌트가 마운트 됐는지 여부를 리턴합니다.
 * @returns
 */
export const useMounted = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted;
};
