/** 구글 플레이스토어 - 한화생명 앱  */
export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.hanwhalife.hiw';

/**  - 한화생명 앱  */
export const APPLE_STORE_URL = 'https://apps.apple.com/kr/app/id1448335675';

/** 구글 플레이스토어 - 한화생명 앱  */
export const APP_INFO_URL = 'https://m.hanwhalife.com/mobile/customerCenter/MCU_0000000_000000.do';

/** 오즈 생성시 사용되는 URL */
export const OZ_STAMP_URL = 'http://oz.hanwhalife.com:7002/hanwhalife/hplus/nc/udw/images/key_symbol.gif';

/** 다이렉트 보험 - 채팅으로 상담하기 URL */
export const CONSULT_DIRECT_CHAT_URL =
  'https://hanwhalifedirect-guest.messaging.kr/?partitionId=hanwhalifedirect&appChannelId=web';

/** 다이렉트 보험 - 카카오톡으로 상담하기 stage URL */
export const CONSULT_DIRECT_KAKAO_STG_URL =
  'https://bizmessage.kakao.com/chat/open/@ndc57zwcodi2n2z?bot=true&event=scenario*';

/** 다이렉트 보험 - 카카오톡으로 상담하기 prod URL */
export const CONSULT_DIRECT_KAKAO_PROD_URL = 'https://bizmessage.kakao.com/chat/open/@onsure?bot=true&event=scenario*';
