import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import weekOfYear from 'dayjs/plugin/weekOfYear';

/*****************************
 * 문자열 관련 유틸
 ******************************/

dayjs.extend(weekOfYear);
dayjs.locale('ko');

const HLI_LOCALE = 'ko-KR';
const HLI_DEFAULT_CURRENCY = 'KRW';

/**
 * replace all prototype 추가(for 크로스 브라우징)
 */
export const initializeStringUtils = () => {
  // replaceAll Override
  if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (search, replace) {
      return this.split(search).join(replace as string);
    };
  }
};

/**
 * UUID 얻기
 * @returns
 */
export const getUuid = () => {
  const localUuid = localStorage.getItem('direct_uuid');

  if (localUuid?.length === 38) {
    return localUuid;
  } else {
    const newUuid = create_UUID();
    localStorage.setItem('direct_uuid', newUuid);

    return newUuid;
  }
};

/**
 * UUID 생성
 * @returns uuid
 */
export const create_UUID = () => {
  let dt = new Date().getTime();
  const uuid = 'directxxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);

    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
};

/**
 * 금액 표시
 */
export const getAmountFormat = (amount?: number | string, options?: Intl.NumberFormatOptions) => {
  // 변환 객체
  const convert = getNumberFormatObject(undefined, undefined, options);

  let convertAmout = amount;
  if (typeof amount === 'string') {
    convertAmout = Number(amount.replaceAll(',', ''));
  }

  // 9007199254740992 ~ -9007199254740992 사이인 숫자인 경우
  return Number.isSafeInteger(convertAmout) ? convert.format(Number(convertAmout)) : '';
};

/**
 * Intl.NumberFormat 객체 생성
 * @param options
 * @returns
 */
export const getNumberFormatObject = (
  locale = 'ko-KR',
  currency = 'KRW',
  options?: Intl.NumberFormatOptions
): Intl.NumberFormat => {
  return new Intl.NumberFormat(locale, { currency, ...options });
};

/**
 * 날짜를 포맷형태로 리턴
 * @param 20200101 string
 * @param 'YYYY.MM.DD
 * @returns 포맷화된 날짜
 */
export const convertDateFormat = (value?: string, format?: string): string => {
  if (!value) {
    return '';
  }
  try {
    return dayjs(value).format(format || 'YYYY.MM.DD');
  } catch (err) {
    console.error(err);

    return '';
  }
};

/**
 * 파일명이 최대 글자 수 이상이면 말줄임 표시하고 파일 확장자명이 붙는 형태로 변환
 * @param fileName 파일명
 * @param maxLength 최대 글자 수
 * @returns string
 */
export const convertFileName = (fileName: string, maxLength: number): string => {
  const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('');

  if (fileNameWithoutExtension.length >= maxLength) {
    const truncatedFileName = `${fileNameWithoutExtension.substring(0, maxLength)}...`;
    const extension = fileName.split('.').pop();

    return `${truncatedFileName}.${extension}`;
  } else {
    return fileName;
  }
};

/**
 * 콤마 금액 변환
 * @param amount
 * @returns
 */
export const convertCommaAmount = (amount?: number | string, options?: Record<string, unknown>): string => {
  try {
    // 변환 객체
    const convert = getNumberFormatObject_INT(options);

    let convertAmout = amount;
    if (typeof amount === 'string') {
      convertAmout = Number(amount.replaceAll(',', ''));
    }

    // 9007199254740992 ~ -9007199254740992 사이인 숫자인 경우
    if (Number.isSafeInteger(convertAmout)) {
      return convert.format(Number(convertAmout));
    } else {
      // 지원불가
      return '0';
    }
  } catch (err) {
    console.log(err);

    return '';
  }
};

/**
 * Intl.NumberFormat 객체 생성 - 채널통합
 * @param options
 * @returns
 */
export const getNumberFormatObject_INT = (options?: Record<string, unknown>): Intl.NumberFormat => {
  return new Intl.NumberFormat(HLI_LOCALE, { currency: HLI_DEFAULT_CURRENCY, ...options });
};

/**
 *
 * @returns uuid - 채널통합
 */
export const create_UUID_INT = () => {
  let dt = new Date().getTime();
  const uuid = 'hlixxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);

    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });

  return uuid;
};

/**
 * Copy To Clipboard
 */
export const copyToClipboard = (inputVal: string): boolean => {
  if (document) {
    // execCommand의 경우 input, textarea에서만 가능하기 떄문에 요소 생성
    const element = document.createElement('textarea');
    element.value = inputVal;

    // 안보이게
    element.setAttribute('readonly', '');
    element.style.position = 'absolute';
    element.style.left = '-9999px';
    document.body.appendChild(element);

    // 실제 복사
    element.select();
    const isSuccess = document.execCommand('copy');

    // 텍스트만 복사하고 삭제
    document.body.removeChild(element);

    if (!isSuccess) {
      console.error('복사 실패');
    }

    return isSuccess;
  } else {
    return false;
  }
};

/**
 *  JSON parse
 * @param data
 * @returns
 */
export const parseData = <T>(data: string): T => {
  return data ? JSON.parse(data) : data;
};

/**
 * 두 문자 사이의 문자를 추출한다
 * @param originStr (string)
 * @param fromStr (string)
 * @param toStr (string)
 * @returns string
 */
export const getExtBetweenStr = (originStr: string, fromStr: string, toStr: string): string => {
  const fromIdx = originStr.indexOf(fromStr);
  const toIdx = originStr.lastIndexOf(toStr);

  if (fromIdx === -1 || toIdx === -1) {
    return '';
  }

  return originStr.slice(fromIdx + 1, toIdx);
};

/**
 * 증권번호 마스킹
 * @param num
 * @returns ex) 1****6789
 */
export const maskingNo = (num: string) => {
  return num.replace(num.substring(1, 5), '****');
};

/**
 *
 * @param base64
 * @returns utf8
 */
export const b64_to_utf8 = (base64: string) => {
  return decodeURIComponent(encodeURIComponent(atob(base64)));
};

/**
 * Base64 형식으로 인코딩
 * @param str 인코딩할 입력 문자열
 * @returns Base64로 인코딩된 문자열
 */
export const base64UrlEncode = (str: string) => {
  if (typeof str !== 'string') {
    return str;
  }
  let base64 = btoa(str);
  base64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

  return base64;
};

/**
 * Base64 형식으로 디코딩
 * @param str 디코딩할 Base64Url 인코딩 문자열
 * @returns 원래의 문자열
 */
export const base64UrlDecode = (str: string) => {
  if (typeof str !== 'string') {
    return str;
  }
  let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
  const padding = base64.length % 4 === 0 ? '' : '='.repeat(4 - (base64.length % 4));
  base64 += padding;

  return atob(base64);
};

/**
 * 휴대폰 번호 split 처리
 * @param phoneNum (01012345678)
 * @returns ex) ['010', '1234', '5678']
 */
export const splitPhoneNum = (phoneNum: string) => {
  return phoneNum.replace(/\D*(\d{3})\D*(\d{4})\D*(\d{4})\D*/, '$1 $2 $3').split(' ');
};

/**
 *문자열을 두자리 숫자형태의 문자열로 변경
 * @param string (1)
 * @returns ex) 01
 */
export const changeNumberToTwoDigitNumber = (str: number) => {
  return String(str).padStart(2, '0');
};

/**
 * 랜딩화면 이동시 앱 버전 체크
 * @param curVer string (현재 앱 버전)
 * @param targetVer string (이동 가능 앱 버전)
 * @returns boolean
 */
export const checkAppVersion = (curVer: string, targetVer: string) => {
  // 버전 정보가 없으면 이동 가능
  if (curVer === '' || targetVer === '') {
    return true;
  }

  const curVerArr = curVer.split('.').map((value) => {
    return parseInt(value);
  });
  const targetVerArr = targetVer.split('.').map((value) => {
    return parseInt(value);
  });

  // 버전 체크
  if (curVerArr[0] < targetVerArr[0]) {
    return false;
  } else if (curVerArr[0] === targetVerArr[0]) {
    if (curVerArr[1] < targetVerArr[1]) {
      return false;
    } else if (curVerArr[1] === targetVerArr[1]) {
      if (curVerArr[2] < targetVerArr[2]) {
        return false;
      }
    }
  }

  return true;
};
