import { SetStateAction, createContext, Dispatch } from 'react';

export interface IAgreeDateControlContext {
  returnData: object;
  setValidation: any;
  setAgreeState: Dispatch<SetStateAction<object>>;
  setMainBtnDisabled: Dispatch<SetStateAction<boolean>>;
}

/**
 * 약관동의 체크 컴포넌트 Context
 */
export const AgreeDateControlContext = createContext<IAgreeDateControlContext>({
  returnData: {},
  setValidation: () => {},
  setAgreeState(data: SetStateAction<object>): void {
    throw new Error(`Function not implemented.${data}`);
  },
  setMainBtnDisabled(bool: SetStateAction<boolean>): void {
    throw new Error(`Function not implemented.${bool}`);
  }
});
