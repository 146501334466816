export const COMMON_MSG = {
  uniqueId: 'EXPIRE_TOKEN',
  type: 'dialog',
  title: '로그아웃 안내',
  bodyContents: (
    <>
      <div className="typography-body2 text-body2">
        로그인 인증 정보가 만료되어 로그아웃했어요. <br />
        다시 로그인 후 이용해주세요.
      </div>
    </>
  ),
  mainBtnText: '확인'
};
