import { useModalStore, useSpinnerStore, useToastStore } from '@hanwhalife/shared-store';
import { Spinner, Modal, Toast } from '@hanwhalife/design-system';

/**
 * Hlp Frontend 전역 store 기반으로 동작하는 글로벌한 UI 컴포넌트를 관리합니다.
 * @returns
 */
export const HlpGlobalUIs = () => {
  const dialogList = useModalStore((state) => state.dialogList);
  const isActive = useSpinnerStore((state) => state.isActive);
  const toastData = useToastStore((state) => state.toastData);

  return (
    <>
      {dialogList.map((modalData, index) => (
        <Modal key={index} {...modalData} bodyContentsClassName={`modal-${modalData.type || 'dialog'}`} showModal />
      ))}
      {toastData.map((toast, index) => {
        const { toastId, isShow = true, ...rest } = toast;

        return <Toast key={index} {...rest} isShow={isShow} />;
      })}
      <Spinner isSpinning={isActive} position="fixed-center" />
    </>
  );
};
