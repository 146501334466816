import { useState } from 'react';

/**
 *
 * Spinner Hook
 *  : Spinner Context를 반환하는 Hook
 *
 */
export const useGlobalSpinner = () => {
  // Spinner 상태
  const [isActive, setActive] = useState<boolean>(false);

  // Show spinner
  //  : (optional) duration을 넣으면 설정된 시간 이후에 Spinner Off (시간 기준 : ms)
  const show = (duration?: number) => {
    setActive(true);

    if (duration) {
      const timer = setTimeout(() => {
        setActive(false);
        clearTimeout(timer);
      }, duration);
    }
  };

  // Hide spinner
  const hide = () => {
    setActive(false);
  };

  return {
    isActive,
    show,
    hide
  };
};
