import { MutableRefObject, useEffect, useRef } from 'react';

const focusableElements =
  'button:not(:disabled), [href], input:not(:disabled), select:not(:disabled), textarea:not(:disabled), [tabindex]:not([tabindex="-1"])';

/**
 * Tab Trap Hook
 * focusElement 노드 안에서 탭 포커스가 순환됩니다.
 * @param focusElement
 */
export const useTabTrap = (focusElement: MutableRefObject<HTMLDivElement>) => {
  const { prevFocusableElement, firstFocusableElement, lastFocusableElement } = useModalFocusElement(focusElement);

  // 포커스 처리
  useEffect(() => {
    const firstFocusableElementCurrent = firstFocusableElement?.current;
    const prevFocusableElementCurrent = prevFocusableElement?.current;

    // 첫 번째 포커스
    firstFocusableElementCurrent?.focus();

    return () => {
      prevFocusableElementCurrent?.focus();
    };
  }, [prevFocusableElement, firstFocusableElement]);

  // 탭처리
  useEffect(() => {
    const startFocusElement = focusElement.current;
    const firstFocusableElementCurrent = firstFocusableElement?.current;
    const lastFocusableElementCurrent = lastFocusableElement?.current;

    // 탭 포커스 이벤트 핸들러
    const handleOnTabFocus = (e: KeyboardEvent) => {
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElementCurrent) {
          // shift + tab
          lastFocusableElementCurrent?.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElementCurrent) {
          // tab
          firstFocusableElementCurrent?.focus();
          e.preventDefault();
        }
      }
    };
    if (startFocusElement) {
      startFocusElement.addEventListener('keydown', handleOnTabFocus, true);
    }

    return () => {
      if (startFocusElement) {
        startFocusElement.removeEventListener('keydown', handleOnTabFocus, true);
      }
    };
  });
};

/**
 * 기준 요소에서 모달호출전 포커스된 요소, 모달내 첫번째 마지막 포커스 가능 요소 리턴해주는 훅
 * @param focusElement
 * @returns
 */
const useModalFocusElement = (focusElement: MutableRefObject<HTMLDivElement>) => {
  // 모달 호출전 이전에 포커스된 요소
  const prevFocusableElement = useRef<HTMLDivElement>();

  // 모달 내 첫번째 포커스 가능 요소
  const firstFocusableElement = useRef<HTMLDivElement>();

  // 모달 내 마지막 포커스 가능 요소
  const lastFocusableElement = useRef<HTMLDivElement>();

  useEffect(() => {
    const focusCurrElement = focusElement.current;
    prevFocusableElement.current = document.activeElement as HTMLDivElement;
    if (focusCurrElement) {
      const focusableEls = Array.from(focusCurrElement?.querySelectorAll(focusableElements));
      firstFocusableElement.current = focusableEls[0] as HTMLDivElement;
      lastFocusableElement.current = focusableEls[focusableEls.length - 1] as HTMLDivElement;
    }
  });

  return { prevFocusableElement, firstFocusableElement, lastFocusableElement };
};
