import { useEffect } from 'react';
import { debounce } from '@hanwhalife/shared-libs/lodash';

/**
 *
 * 약관 iframe 내부 콘텐츠로 부모 창 크기 전달를 전달합니다.
 * @param param0
 */
export const useParentFrameSize = () => {
  const handleWindowSizeToIframe = debounce(() => {
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach((iframe) => {
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({ width: window.innerWidth }, '*');
      }
    });
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeToIframe);

    return () => {
      window.removeEventListener('resize', handleWindowSizeToIframe);
    };
  }, []);

  return { handleWindowSizeToIframe };
};
