import { createContext } from 'react';
import { IGlobal_Spinner, IGlobal_Modal } from './global-ui.types';

/**
 *
 * Global UI Context
 * : 앱 전역에서 사용할 Context
 *
 * - Modal
 * - Spinner
 *
 */
export interface IGlobalUI_Context {
  spinner: IGlobal_Spinner;
  modal: IGlobal_Modal;
}

const defaultValue: IGlobalUI_Context = {
  // Spinner
  spinner: {
    isActive: false,
    show: () => {},
    hide: () => {}
  },
  // Modal
  modal: {
    list: [],
    openModal: () => {},
    alert: () => new Promise(() => {}),
    confirm: () => new Promise(() => {}),
    closeModal: () => {},
    closeAllModal: () => {}
  }
};

export const GlobalUIContext = createContext<IGlobalUI_Context>(defaultValue);
