import { getLastDay } from './dateUtil';

/*****************************
 * 나이 관련 유틸
 ******************************/

/**
 *  만나이를 계산합니다.
 * @param oJumin
 * @returns
 */
export function getFullAge(oJumin: string) {
  let yBirth;
  let mBirth;
  let dBirth;

  if (oJumin.length === 8) {
    yBirth = parseFloat(oJumin.substring(0, 4));
    mBirth = parseFloat(oJumin.substring(4, 6));
    dBirth = parseFloat(oJumin.substring(6, 8));
  } else if (oJumin.length === 6) {
    if (oJumin.substring(0, 1) === '0' || oJumin.substring(0, 1) === '1' || oJumin.substring(0, 1) === '2') {
      yBirth = parseFloat(oJumin.substring(0, 2)) + 2000; //생년월일
    } else {
      yBirth = parseFloat(oJumin.substring(0, 2)) + 1900; //생년월일
    }
    mBirth = parseFloat(oJumin.substring(2, 4));
    dBirth = parseFloat(oJumin.substring(4, 6));
  } else {
    console.error('getManAge >> 나이 입력 포멧 오류');

    return -1;
  }

  const curDate = new Date(); // 오늘
  const yToday = curDate.getFullYear(); //getFullYear()는 ie, 파폭, 크롬에서 같은값 호출
  const mToday = curDate.getMonth() + 1;
  const dToday = curDate.getDate();

  let yDiff = yToday - yBirth;
  let mDiff = mToday - mBirth - (dToday < dBirth ? 1 : 0);

  if (mDiff < 0) {
    mDiff += 12;
    --yDiff;
  }
  if (yDiff < 0) {
    return -1;
  }

  return yDiff;
}

/**
 * 보험 나이를 계산합니다.
 * @param oJumin
 * @returns
 */
export function getInsuranceAge(oJumin: string) {
  let yBirth;
  let mBirth;
  let dBirth;

  if (oJumin.length === 8) {
    yBirth = parseFloat(oJumin.substring(0, 4));
    mBirth = parseFloat(oJumin.substring(4, 6));
    dBirth = parseFloat(oJumin.substring(6, 8));
  } else if (oJumin.length === 6) {
    if (oJumin.substring(0, 1) === '0' || oJumin.substring(0, 1) === '1' || oJumin.substring(0, 1) === '2') {
      yBirth = parseFloat(oJumin.substring(0, 2)) + 2000; //생년월일
    } else {
      yBirth = parseFloat(oJumin.substring(0, 2)) + 1900; //생년월일
    }
    mBirth = parseFloat(oJumin.substring(2, 4));
    dBirth = parseFloat(oJumin.substring(4, 6));
  } else {
    console.error('getInsuAge >> 나이 입력 포멧 오류');

    return -1;
  }

  const curDate = new Date(); // 오늘
  const yToday = curDate.getFullYear(); //getFullYear()는 ie, 파폭, 크롬에서 같은값 호출
  const mToday = curDate.getMonth() + 1;
  const dToday = curDate.getDate();

  let yDiff = yToday - yBirth;
  let mDiff = mToday - mBirth - (dToday < dBirth ? 1 : 0);

  if (mDiff < 0) {
    mDiff += 12;
    --yDiff;
  }
  if (yDiff < 0) {
    return -1;
  }

  if (mDiff === 5 && dToday < dBirth && dToday === getLastDay(yToday, mToday)) {
    ++mDiff;
  }

  return yDiff;
}

/**
 * 입력되어잔 생년월일 값과 주민등록번호 뒤 첫째자리 값을 통해 만나이를 반환합니다.
 * 에러: -1 반환
 * @param front 주민등록번호 앞 6자리
 * @param back 주민등록번호 뒤 첫자리
 * @returns number
 */
export const getAge = (front: string, back: string) => {
  const year = parseInt(front.slice(0, 2));
  const month = parseInt(front.slice(2, 4));
  const day = parseInt(front.slice(4, 6));
  let birthYear = 0;

  switch (back) {
    case '1':
    case '2':
    case '5':
    case '6':
      birthYear = 1900 + year;
      break;
    case '3':
    case '4':
    case '7':
    case '8':
      birthYear = 2000 + year;
      break;
    case '9':
    case '0':
      birthYear = 1800 + year;
      break;
    default:
      return -1;
  }

  const currdate = new Date();

  let yDiff = currdate.getFullYear() - birthYear;
  let mDiff = currdate.getMonth() + 1 - month - (currdate.getDate() < day ? 1 : 0);

  if (mDiff < 0) {
    mDiff += 12;
    --yDiff;
  }
  if (yDiff < 0) {
    return -1;
  }

  return yDiff;
};

/**
 * 주민등록번호 앞자리와 뒷자리 첫 번째 숫자를 사용하여 8자리 생년월일 반환
 * @param front 주민등록번호 앞자리(YYMMDD 형식의 6자리 숫자)
 * @param back 주민등록번호 뒷자리의 첫 번째 숫자 (성별 및 세기 정보를 포함)
 * @returns {string} 완전한 생년월일 문자열 (YYYYMMDD 형식) 또는 -1 (잘못된 입력의 경우)
 */
export function getBirthYear(front: string, back: string): string {
  const year = front.slice(0, 2);
  const monthDay = front.slice(2, 6);

  let centuryOffset;
  if (['1', '2', '5', '6'].includes(back)) {
    centuryOffset = 1900;
  } else if (['3', '4', '7', '8'].includes(back)) {
    centuryOffset = 2000;
  } else if (['9', '0'].includes(back)) {
    centuryOffset = 1800;
  } else {
    return '-1';
  }

  const birthYear = centuryOffset + parseInt(year, 10);

  return `${birthYear}${monthDay}`;
}

/**
 * loginInfo.birthWithResidentRegistrationNumberLastDigit 값으로 외국인 여부 반환 (5,6,7,8)
 */
export function isForeignerNumber(rrn: string) {
  const pattern = /^\d{6}[5-8]$/;

  return pattern.test(rrn);
}
