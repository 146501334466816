import CryptoJS from 'crypto-js';

// eslint-disable-next-line turbo/no-undeclared-env-vars
const secretKey = process.env.NEXT_PUBLIC_AES_SECRET_KEY as string;

/**
 * AES 암호화
 * @param plaintext
 * @returns ciphertext
 */
export const encryptAES = (value: string) => {
  if (!value) {
    return '';
  }

  return CryptoJS.AES.encrypt(value, secretKey).toString();
};

/**
 * AES 암호화 (encodeURI)
 * @param plaintext
 * @returns ciphertext
 */
export const encodeURIEncryptAES = (value: string) => {
  return encodeURIComponent(encryptAES(value));
};

/**
 * AES 복호화
 * @param ciphertext
 * @returns plaintext
 */
export const decryptAES = (ciphertext: string) => {
  if (!ciphertext) {
    return '';
  }

  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);

  return bytes.toString(CryptoJS.enc.Utf8);
};

/**
 * AES 복호화 (decodeURI)
 * @param ciphertext
 * @returns plaintext
 */
export const decodeURIDecryptAES = (ciphertext: string) => {
  return decodeURIComponent(decryptAES(ciphertext));
};
