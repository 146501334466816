import { useEffect } from 'react';
import { useLoginInfo } from '@hanwhalife/shared-store';
import { isLoginRequiredPage, isNoAccessWhenLogin } from '@hanwhalife/shared-utils/validationUtil';
import { useHlpRouter } from '@reactjs/router';

/**
 * 로그인, 미로그인 각각 특정 페이지 접근시 라우팅 처리
 * @returns
 */
export const ProtectedRoute = () => {
  return (
    <>
      {/* 로그인 필요 페이지 접근시 라우팅 처리 */}
      <LoginRequiredRoute />

      {/* 미로그인 상태에서만 접근 가능한 라우팅 처리 */}
      <NotLoginOnlyRoute />
    </>
  );
};

/**
 * 로그인 필요 페이지 접근시 라우팅 처리
 * @returns
 */
const LoginRequiredRoute = () => {
  const {
    loginInfo: { isLogined }
  } = useLoginInfo();
  const router = useHlpRouter();

  useEffect(() => {
    const handleRouteChange = (pathname: string) => {
      if (isLogined && isNoAccessWhenLogin(pathname)) {
        // 로그인시 막혀야하는 페이지는 / 루트로 이동
        return router.push('/');
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogined]);

  return <></>;
};

/**
 * 미로그인 상태에서만 접근 가능한 라우팅 처리
 * 미로그인 상태에서 로그인필요 페이지 접근시 로그인 페이지로 이동
 * @returns
 */
const NotLoginOnlyRoute = () => {
  const {
    loginInfo: { isInitLoginInfo, isLogined }
  } = useLoginInfo();
  const router = useHlpRouter();

  useEffect(() => {
    const handleRouteChange = (pathname: string) => {
      if (isInitLoginInfo && !isLogined && isLoginRequiredPage(pathname)) {
        // 미로그인시 막혀야하는 페이지
        return router.goToLoginPage();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitLoginInfo, isLogined]);

  return <></>;
};
