/* eslint-disable turbo/no-undeclared-env-vars */
export const HLP_API_HOST = process.env.NEXT_PUBLIC_HLP_API_HOST ?? process.env.STORYBOOK_HLP_API_HOST;
export const NCI_API_HOST = process.env.NEXT_PUBLIC_NCI_API_HOST ?? process.env.STORYBOOK_NCI_API_HOST;
export const BFC_API_HOST = process.env.NEXT_PUBLIC_BFC_API_HOST ?? process.env.STORYBOOK_BFC_API_HOST;
export const TOM_API_HOST = process.env.NEXT_PUBLIC_TOM_API_HOST ?? process.env.STORYBOOK_TOM_API_HOST;
export const HLI_CDN_HOST = process.env.NEXT_PUBLIC_CDN_HOST ?? process.env.STORYBOOK_CDN_HOST;
export const HLI_CDN_HOST_PROD = 'https://static.hanwhalife.com';
export const HLI_CDN_HOT_DEV = 'https://dev-static.hanwhalife.com';
export const HLI_CDN_STATIC = process.env.NEXT_PUBLIC_CDN_STATIC_HOST ?? process.env.STORYBOOK_CDN_STATIC_HOST;
export const HLI_CDN_STATIC_PROD = 'https://static.hanwhalife.com/static';
export const HLI_CDN_STATIC_DEV = 'https://dev-static.hanwhalife.com/static';
export const HLI_CDN_WEBFONT_PREFIX = `${HLI_CDN_STATIC_PROD}/ds/font/hanwha-gothic`;
export const HOMEPAGE_HOST = process.env.NEXT_PUBLIC_HOMEPAGE_HOST ?? process.env.STORYBOOK_HOMEPAGE_HOST;
export const HOMEPAGE_PC_HOST = process.env.NEXT_PUBLIC_HOMEPAGE_PC_HOST ?? process.env.STORYBOOK_HOMEPAGE_PC_HOST;
export const DIRECT_HOST = process.env.NEXT_PUBLIC_DIRECT_HOST ?? process.env.STORYBOOK_DIRECT_HOST;
export const ONSURE_HOST = process.env.NEXT_PUBLIC_ONSURE_HOST ?? process.env.STORYBOOK_ONSURE_HOST;
export const HLP_WEBVIEW_HOST = process.env.NEXT_PUBLIC_HLP_WEBVIEW_HOST ?? process.env.STORYBOOK_HLP_WEBVIEW_HOST;
export const BIZAPP_HOST = process.env.NEXT_PUBLIC_BIZAPP_HOST ?? process.env.STORYBOOK_BIZAPP_HOST;
export const HLMCHUB_HOST = process.env.NEXT_PUBLIC_HLMCHUB_HOST ?? process.env.STORYBOOK_HLMCHUB_HOST;
